import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

import { host } from './share'

const useFetchFairPrice = ({ symbol, date }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({call: [], center: [], put: [], price: 0, dvol: 0, date: [moment().add(1, 'days').format('DDMMMYY').toUpperCase(), moment().add(2, 'days').format('DDMMMYY').toUpperCase(), moment().add(3, 'days').format('DDMMMYY').toUpperCase()]})

    const fetchData = () => {
        const cancelToken = axios.CancelToken.source()
        const url = `http://${host}:5001/get_FR`;
    
        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Content-Type": "application/json",
                "x-access-token": (window.localStorage.getItem('token')) ? window.localStorage.getItem('token') : "N"
            },
            data:{symbol: symbol, date: date}
        },
        {
            cancelToken: cancelToken.token
        })
    
        .then((response) => {
            if (response.data.FairPrice && response.data.dates) {
                let fairPrice = response.data.FairPrice
                let dates = response.data.dates

                setData(prev => {
                    return {
                        ...prev,
                        call: fairPrice[0],
                        put: fairPrice[1],
                        center: fairPrice[2],
                        date: dates
                    }
                })
            } else {
                console.log(response.data);
            }

            setLoading(false)        
        })
        
        .catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    const fetchPrice = () => {
        const cancelToken = axios.CancelToken.source()
        const url = `http://${host}:5001/get_index`;
    
        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Content-Type": "application/json",
                "x-access-token": 'N'
            },
            symbol
        },
        {
            cancelToken: cancelToken.token
        })
    
        .then((response) => {
            if (response.data.result) {
                setData(prev => { return { ...prev, price: response.data.result } })
            } else {
                console.log(response.data);
            }
        })
        
        .catch((err) => {
            console.log(err)
        })
    }

    const fetchDVOL = () => {
        const cancelToken = axios.CancelToken.source()
        const url = `http://${host}:5001/getDVOL`;
    
        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Content-Type": "application/json",
                "x-access-token": 'N'
            },
            symbol
        },
        {
            cancelToken: cancelToken.token
        })
    
        .then((response) => {
            if (response.data.result) {
                setData(prev => { return { ...prev, dvol: response.data.result } })
            } else {
                console.log(response.data);
            }    
        })
        
        .catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        setLoading(true)
        fetchData()
        fetchPrice()
        fetchDVOL()
        
        let intervalID;
        
        intervalID = setInterval(() => {
            fetchData()
            fetchPrice()
            fetchDVOL()
        }, 5000);

        return () => clearInterval(intervalID)
    }, [symbol, date])
    
    return [loading, data]
}

export default useFetchFairPrice