import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment/moment'

import RegisterReusable from './RegisterReusable'
import useFetchFairPrice from '../hooks/useFetchFairPrice'

const FairPrice = ({ setPathname }) => {
  const location = useLocation()
  const priceRef = useRef()

  const [currency, setCurrency] = useState('BTC')
  const [date, setDate] = useState(moment().add(1, 'days').format('DDMMMYY').toUpperCase())
  const [showAll, setShowAll] = useState(true)
  const [expiry, setExpiry] = useState('')
  const [priceIncrease, setPriceIncrease] = useState(true)
  const [alertStrike, setAlertStrike] = useState([])

  const fetch = useFetchFairPrice({ symbol: currency, date: date })
  const [loading, data] = fetch

  useEffect(() => {
    setPathname(location.pathname);
  }, [])

  useEffect(() => {
    let intervalID;

    intervalID = setInterval(() => {
      const today = moment().utcOffset(8)
      const nextDay = moment(date).utcOffset(8).add(16, 'hours')
      let diff = moment.duration(nextDay.diff(today)).asMilliseconds();
      setExpiry(`${moment.duration(diff).days()}d - ${moment.duration(diff).hours()}h ${moment.duration(diff).minutes()}m ${moment.duration(diff).seconds()}s`)
    }, 100);

    return () => clearInterval(intervalID)
  }, [date])

  useEffect(() => {
    if (data.price > priceRef.current) {
      setPriceIncrease(true)
    } else {
      setPriceIncrease(false)
    }
    
    priceRef.current = data.price
  }, [data.price])

  useEffect(() => {
    let call = data.call.filter(f => f.Alert !== '-').map(e => e.instrument.split('-')[2]*1)
    let put = data.put.filter(f => f.Alert !== '-').map(e => e.instrument.split('-')[2]*1)

    let result = Array.from(new Set(call.concat(put))).sort((a, b) => a - b)

    setAlertStrike(result)
  }, [data.call])

  return (
    <div className='fixed top-0 left-[200px] h-full w-[calc(100%-200px)] duration-200 xl:left-0 xl:w-full'>
      <div className='h-[40px] w-full text-xl text-yellow-400 font-semibold px-6 flex items-center'>Options Pricing</div>
      
      <div className={`h-[calc(100%-40px)] w-full flex flex-col px-3 pb-2.5 gap-2.5 overflow-y-scroll`}>
        {(window.localStorage.getItem('subscribe') === 'true') ? <></> : <RegisterReusable/>}

        {/* selection */}
        <div className='h-fit w-full'>
          <div className='h-fit w-full bg-[#222222] rounded-xl text-sm p-3 grid grid-cols-[150px,1fr] gap-5 md:grid-cols-1 md:gap-2.5'>
            <div>
              <p>Select Crypto:</p>
              <select value={currency} onChange={e => setCurrency(e.target.value)} className='h-8 w-full bg-transparent border border-white rounded-md cursor-pointer duration-200 hover:bg-white hover:text-black md:max-w-[150px]'>
                <option className='bg-black' value='BTC'>Bitcoin</option>
                <option className='bg-black' value='ETH'>Ethereum</option>
              </select>
            </div>
            
            {(loading) ? (
              <div className='relative w-[60px] h-full flex items-center justify-evenly'>
                <div className='w-1/12 bg-white animate-loading-bars' style={{ animationDelay: '-0.24s' }}/>
                <div className='w-1/12 bg-white animate-loading-bars' style={{ animationDelay: '-0.12s' }}/>
                <div className='w-1/12 bg-white animate-loading-bars' style={{ animationDelay: '0' }}/>
              </div>
            ) : (
              <div>
                <p>Select Date:</p>
                <div className='flex flex-wrap items-center gap-2.5'>
                  {data.date.map((e, i) => (
                    <div onClick={() => setDate(e)} key={i} className={`h-8 border rounded-full cursor-pointer flex items-center justify-center px-2 duration-200 hover:bg-white hover:text-black ${(date === e) ? 'border-yellow-400 bg-yellow-400 text-black' : 'border-white bg-transparent'}`}>{e}</div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* table container */}
        <div className='h-fit w-full'>
          <div className='h-fit w-full text-sm bg-[#222222] p-3 rounded-xl grid grid-flow-row'>
            {/* info */}
            <div className='h-fit w-full flex gap-5 mb-2 sm:flex-col sm:gap-2.5'>
              <div className='flex items-center gap-2'>
                Show All
                <div onClick={() => setShowAll(prev => !prev)} className='h-[20px] flex items-center cursor-pointer'>
                  <div className={`relative h-[10px] min-w-[35px] rounded-full duration-500 ${(showAll) ? 'bg-blue-300' : 'bg-[#777777]'}`}>
                    <div className={`absolute top-[-5px] h-[20px] w-[20px] rounded-full duration-500 ${(showAll) ? 'bg-blue-700 left-[15px]' : 'bg-[#fff] left-[0px]'}`}/>
                  </div>
                </div>
              </div>
              
              <div className='flex flex-wrap items-center flex-1 justify-end gap-x-5 sm:justify-start'>
                <p><span className='text-yellow-400'>Price:&nbsp;</span><span className={`font-normal ${(priceIncrease) ? 'text-green-400' : 'text-red-400'}`}>$ {data.price.toFixed(2).toLocaleString()}</span></p>
                <p><span className='text-yellow-400'>DVOL:&nbsp;</span><span className='font-normal'>{data.dvol.toFixed(2)}</span></p>
                <p><span className='text-yellow-400'>Expiry:&nbsp;</span><span className='font-normal'>{expiry}</span></p>
              </div>
            </div>

            {(loading) ? (
              <>
                {/* loading skeleton */}
                <div className='h-[200px] w-full animate-pulse rounded-lg grid grid-rows-[40px,1fr]'>
                  <div className='h-full w-full grid grid-cols-2'>
                    <div className='h-full w-full flex items-center justify-center'>
                      <div className='h-3/4 w-11/12 bg-[#555555] rounded-full'/>
                    </div>
                    <div className='h-full w-full flex items-center justify-center'>
                      <div className='h-3/4 w-11/12 bg-[#555555] rounded-full'/>
                    </div>
                  </div>
                  <div className='h-full w-full flex flex-col'>
                    <div className='h-full w-full grid grid-cols-5'>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                    </div>
                    <div className='h-full w-full grid grid-cols-5'>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                    </div>
                    <div className='h-full w-full grid grid-cols-5'>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* table head */}
                <div className='h-fit w-full bg-black grid grid-cols-2 border-x-2 border-t-2 border-[#444444] rounded-t-lg'>
                  <p className='p-2 text-center font-semibold text-[#CCCCCC] border-r border-[#444444]'>CALLS</p>
                  <p className='p-2 text-center font-semibold text-[#CCCCCC] border-l border-[#444444]'>PUTS</p>
                </div>

                {/* main table */}
                <div className='h-fit min-h-[150px] w-full text-xs grid grid-cols-[1fr,80px,1fr] border border-[#444444] overflow-y-scroll'>

                  {/* <div className={`bg-black overflow-y-scroll ${(showAll) ? '' : 'border-r border-[#444444]'}`} id='calls' onScroll={e => document.getElementById('puts').scroll(e.target.scrollLeft, 0)}> */}
                  <div className={`bg-black ${(showAll) ? '' : 'border-r border-[#444444]'}`}>
                    <table className='w-full'>
                      <thead>
                        <tr>
                          <th className='h-10 min-w-[80px] bg-yellow-200/20 text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Alert</th>
                          <th className='h-10 min-w-[80px] bg-yellow-200/20 text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Fair Price</th>
                          <th className='h-10 min-w-[80px] bg-yellow-200/20 text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Value{'(%)'}</th>
                          <th className='h-10 min-w-[80px] bg-yellow-200/20 text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Above{'(%)'}</th>
                          <th className='h-10 min-w-[80px] bg-yellow-200/20 text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Below{'(%)'}</th>
                          <th className='h-10 min-w-[80px] bg-green-400/20 text-center text-green-400 font-semibold px-1 border border-[#444444] border-l-[2px] border-l-[#FFFFFF]'>Bid</th>
                          <th className='h-10 min-w-[80px] bg-green-400/20 text-center text-green-400 font-semibold px-1 border border-[#444444]'>Ask</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(showAll) ? (
                          <>
                            {data.call.map((e, i) => (
                              <tr key={i} className='odd:bg-[#222222]'>
                                {(e.instrument.split('-')[2]*1 === data.center[i]) ? (
                                  <>
                                    <td className={`h-10 px-1 text-center border border-[#444444] ${(e.Alert === 'LONG' ? 'text-green-400' : (e.Alert === 'SHORT' ? 'text-red-400' : 'text-white'))}`}>
                                      {(e.Alert === 'LONG' || e.Alert === 'SHORT') ? (
                                        <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline hover:text-blue-500 hover:underline hover:underline-offset-4'>
                                          {e.Alert}
                                        </a>
                                      ) : (
                                        <>{e.Alert}</>
                                      )}
                                    </td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>{e["Fair Price"]}</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>{e["Value %"]}</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>{e["Pabove %"]}</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>{e["Pbelow %"]}</td>
                                    <td className='h-10 px-1 bg-black text-center border-x border-[#444444] border-l-[2px] border-l-[#FFFFFF]'>
                                      {e.bid ? (
                                        <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline text-green-400 hover:text-blue-500 hover:underline hover:underline-offset-2'>
                                          {e.bid}
                                        </a>
                                      ) : ''}
                                      <p>
                                        {e.bid ? (
                                          <>
                                            <span className='text-[#AAAAAA]'>$&nbsp;</span>
                                            {(e.bid * data.price).toFixed(2)}
                                          </>
                                        ) : (
                                          <span className='text-green-400'>-</span>
                                        )}
                                      </p>
                                    </td>
                                    <td className='h-10 px-1 bg-black text-center border-x border-[#444444]'>
                                      {e.ask ? (
                                        <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline text-red-400 hover:text-blue-500 hover:underline hover:underline-offset-2'>
                                          {e.ask}
                                        </a>
                                      ) : ''}
                                      <p>
                                        {e.ask ? (
                                          <>
                                            <span className='text-[#AAAAAA]'>$&nbsp;</span>
                                            {(e.ask * data.price).toFixed(2)}
                                          </>
                                        ) : (
                                          <span className='text-red-400'>-</span>
                                        )}
                                      </p>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                    <td className='h-10 px-1 text-center border border-[#444444] border-l-[2px] border-l-[#FFFFFF]'>-</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </>
                        ) : (
                          <>
                            {alertStrike.map((e, i) => {
                              let alertOnly = data.call.filter(f => f.Alert !== '-').find(f => f.instrument.split('-')[2]*1 === e)

                              return (
                                <tr key={i} className='odd:bg-[#222222]'>
                                  {(alertOnly) ? (
                                    <>
                                      <td className={`h-10 px-1 text-center border border-[#444444] ${(alertOnly.Alert === 'LONG') ? 'text-green-400' : 'text-red-400'}`}>
                                        {(alertOnly.Alert === 'LONG' || alertOnly.Alert === 'SHORT') ? (
                                          <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline hover:text-blue-500 hover:underline hover:underline-offset-4'>
                                            {alertOnly.Alert}
                                          </a>
                                        ) : (
                                          <>{alertOnly.Alert}</>
                                        )}
                                      </td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>{alertOnly["Fair Price"]}</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>{alertOnly["Value %"]}</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>{alertOnly["Pabove %"]}</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>{alertOnly["Pbelow %"]}</td>
                                      <td className='h-10 px-1 bg-black text-center border-x border-[#444444] border-l-[2px] border-l-[#FFFFFF]'>
                                        {alertOnly.bid ? (
                                          <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline text-green-400 hover:text-blue-500 hover:underline hover:underline-offset-2'>
                                            {alertOnly.bid}
                                          </a>
                                        ) : ''}
                                        <p>
                                          {alertOnly.bid ? (
                                            <>
                                              <span className='text-[#AAAAAA]'>$&nbsp;</span>
                                              {(alertOnly.bid * data.price).toFixed(2)}
                                            </>
                                          ) : (
                                            <span className='text-green-400'>-</span>
                                          )}
                                        </p>
                                      </td>
                                      <td className='h-10 px-1 bg-black text-center border-x border-[#444444]'>
                                        {alertOnly.ask ? (
                                          <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline text-red-400 hover:text-blue-500 hover:underline hover:underline-offset-2'>
                                            {alertOnly.ask}
                                          </a>
                                        ) : ''}
                                        <p>
                                          {alertOnly.ask ? (
                                            <>
                                              <span className='text-[#AAAAAA]'>$&nbsp;</span>
                                              {(alertOnly.ask * data.price).toFixed(2)}
                                            </>
                                          ) : (
                                            <span className='text-red-400'>-</span>
                                          )}
                                        </p>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                      <td className='h-10 px-1 bg-black text-center border-x border-[#444444] border-l-[2px] border-l-[#FFFFFF]'>-</td>
                                      <td className='h-10 px-1 bg-black text-center border-x border-[#444444]'>-</td>
                                    </>
                                  )}
                                </tr>
                              )
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <table className={`border border-[#444444] bg-black`}>
                    <thead>
                      <tr>
                        <th className='h-10 w-full text-center font-semibold px-1'>Strike</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(showAll) ? (
                        <>
                          {data.center.map((e, i) => (
                            <tr key={i}>
                              <td className='h-10 w-full text-center px-1'>{e}</td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          {alertStrike.map((e, i) => (
                            <tr key={i}>
                              <td className='h-10 w-full text-center px-1'>{e}</td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                  
                  {/* <div className={`bg-black overflow-y-scroll ${(showAll) ? '' : 'border-l border-[#444444]'}`} id='puts' onScroll={e => document.getElementById('calls').scroll(e.target.scrollLeft, 0)}> */}
                  <div className={`bg-black ${(showAll) ? '' : 'border-l border-[#444444]'}`}>
                    <table className='w-full'>
                      <thead>
                        <tr>
                          <th className='h-10 min-w-[80px] bg-green-400/20 text-center text-green-400 font-semibold px-1 border border-[#444444]'>Bid</th>
                          <th className='h-10 min-w-[80px] bg-green-400/20 text-center text-green-400 font-semibold px-1 border border-[#444444] border-r-[2px] border-r-[#FFFFFF]'>Ask</th>
                          <th className='h-10 min-w-[80px] bg-yellow-200/20 text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Alert</th>
                          <th className='h-10 min-w-[80px] bg-yellow-200/20 text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Fair Price</th>
                          <th className='h-10 min-w-[80px] bg-yellow-200/20 text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Value{'(%)'}</th>
                          <th className='h-10 min-w-[80px] bg-yellow-200/20 text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Above{'(%)'}</th>
                          <th className='h-10 min-w-[80px] bg-yellow-200/20 text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Below{'(%)'}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(showAll) ? (
                          <>
                            {data.put.map((e, i) => (
                              <tr key={i} className='odd:bg-[#222222]'>
                                {(e.instrument.split('-')[2]*1 === data.center[i]) ? (
                                  <>
                                    <td className='h-10 px-1 bg-black text-center border-x-2 border-[#444444]'>
                                      {e.bid ? (
                                        <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline text-green-400 hover:text-blue-500 hover:underline hover:underline-offset-2'>
                                          {e.bid}
                                        </a>
                                      ) : ''}
                                      <p>
                                        {e.bid ? (
                                          <>
                                            <span className='text-[#AAAAAA]'>$&nbsp;</span>
                                            {(e.bid * data.price).toFixed(2)}
                                          </>
                                        ) : (
                                          <span className='text-green-400'>-</span>
                                        )}
                                      </p>
                                    </td>
                                    <td className='h-10 px-1 bg-black text-center border-x-2 border-[#444444] border-r-[2px] border-r-[#FFFFFF]'>
                                      {e.ask ? (
                                        <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline text-red-400 hover:text-blue-500 hover:underline hover:underline-offset-2'>
                                          {e.ask}
                                        </a>
                                      ) : ''}
                                      <p>
                                        {e.ask ? (
                                          <>
                                            <span className='text-[#AAAAAA]'>$&nbsp;</span>
                                            {(e.ask * data.price).toFixed(2)}
                                          </>
                                        ) : (
                                          <span className='text-red-400'>-</span>
                                        )}
                                      </p>
                                    </td>
                                    <td className={`h-10 px-1 text-center border border-[#444444] ${(e.Alert === 'LONG' ? 'text-green-400' : (e.Alert === 'SHORT' ? 'text-red-400' : 'text-white'))}`}>
                                      {(e.Alert === 'LONG' || e.Alert === 'SHORT') ? (
                                        <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline hover:text-blue-500 hover:underline hover:underline-offset-4'>
                                          {e.Alert}
                                        </a>
                                      ) : (
                                        <>{e.Alert}</>
                                      )}
                                    </td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>{e["Fair Price"]}</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>{e["Value %"]}</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>{e["Pabove %"]}</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>{e["Pbelow %"]}</td>
                                  </>
                                ) : (
                                  <>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                    <td className='h-10 px-1 text-center border border-[#444444] border-r-[2px] border-r-[#FFFFFF]'>-</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                    <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </>
                        ) : (
                          <>
                            {alertStrike.map((e, i) => {
                              let alertOnly = data.put.filter(f => f.Alert !== '-').find(f => f.instrument.split('-')[2]*1 === e)
                              
                              return (
                                <tr key={i} className='odd:bg-[#222222]'>
                                  {(alertOnly) ? (
                                    <>
                                      <td className='h-10 px-1 bg-black text-center border-x border-[#444444]'>
                                        {alertOnly.bid ? (
                                          <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline text-green-400 hover:text-blue-500 hover:underline hover:underline-offset-2'>
                                            {alertOnly.bid}
                                          </a>
                                        ) : ''}
                                        <p>
                                          {alertOnly.bid ? (
                                            <>
                                              <span className='text-[#AAAAAA]'>$&nbsp;</span>
                                              {(alertOnly.bid * data.price).toFixed(2)}
                                            </>
                                          ) : (
                                            <span className='text-green-400'>-</span>
                                          )}
                                        </p>
                                      </td>
                                      <td className='h-10 px-1 bg-black text-center border-x border-[#444444] border-r-[2px] border-r-[#FFFFFF]'>
                                        {alertOnly.ask ? (
                                          <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline text-red-400 hover:text-blue-500 hover:underline hover:underline-offset-2'>
                                            {alertOnly.ask}
                                          </a>
                                        ) : ''}
                                        <p>
                                          {alertOnly.ask ? (
                                            <>
                                              <span className='text-[#AAAAAA]'>$&nbsp;</span>
                                              {(alertOnly.ask * data.price).toFixed(2)}
                                            </>
                                          ) : (
                                            <span className='text-red-400'>-</span>
                                          )}
                                        </p>
                                      </td>
                                      <td className={`h-10 px-1 text-center border border-[#444444] ${(alertOnly.Alert === 'LONG') ? 'text-green-400' : 'text-red-400'}`}>
                                        {(alertOnly.Alert === 'LONG' || alertOnly.Alert === 'SHORT') ? (
                                          <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline hover:text-blue-500 hover:underline hover:underline-offset-4'>
                                            {alertOnly.Alert}
                                          </a>
                                        ) : (
                                          <>{alertOnly.Alert}</>
                                        )}
                                      </td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>{alertOnly["Fair Price"]}</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>{alertOnly["Value %"]}</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>{alertOnly["Pabove %"]}</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>{alertOnly["Pbelow %"]}</td>
                                    </>
                                  ) : (
                                    <>
                                      <td className='h-10 px-1 bg-black text-center border-x border-[#444444]'>-</td>
                                      <td className='h-10 px-1 bg-black text-center border-x border-[#444444] border-r-[2px] border-r-[#FFFFFF]'>-</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                      <td className='h-10 px-1 text-center border border-[#444444]'>-</td>
                                    </>
                                  )}
                                </tr>
                              )
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* theory */}
        <div className='h-fit w-full'>
          <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-3 flex flex-col gap-1'>
            <p className='text-lg font-semibold'>What is Fair Price?</p>
            <p className='text-sm text-[#CCCCCC]'>A price is fair if both the buyer and the seller have zero expected profit. It equals the probability-weighted future outcomes. Fair Value of an option is equal to its expected payoff at expiration. Difference between the Fair and Market price is the expected profit. Among many facets of fairness, we prefer the one implying that neither party of a trade have an advantage over each other. As the fair price of an option can be calculated, our tool can help our users to scan and find for options with not being in their fair price.</p>
          </div>
        </div>

        <div className='h-fit w-full'>
          <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-3 flex flex-col gap-1'>
            <p className='text-lg font-semibold'>What is "Above{'(%)'}" and "Below{'(%)'}"?</p>
            <p className='text-sm text-[#CCCCCC]'><span className='text-sm text-white font-semibold'>Above{'(%)'} - </span>Chance of price closing above the Strike price.</p>
            <p className='text-sm text-[#CCCCCC]'><span className='text-sm text-white font-semibold'>Below{'(%)'} - </span>Chance of price closing below the Strike price.</p>
            <p className='text-sm text-[#CCCCCC]'>In other words, it's the representation of success rate for the final result of the option taken.</p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default FairPrice