import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import logo from '../assets/logo.png'

const Navbar = ({ changeNavBg, pathname }) => {
    const navigate = useNavigate()
    const[openMenu, setOpenMenu] = useState(false)

    return (
        <div className={`fixed z-50 h-20 w-full px-5 py-2.5 flex flex-row items-center justify-center overflow-visible duration-500 ${(!changeNavBg && pathname === '/') ? 'bg-transparent' : 'bg-black shadow-md shadow-[#333333]'} sm:px-2.5`} >
            <div className={`h-full w-full max-w-[1440px] flex flex-row items-center justify-between gap-3 overflow-visible`} >
                <img src={logo} alt="logo" className='h-full cursor-pointer brightness-150' onClick={() => {(pathname !== '/') && navigate('/'); setOpenMenu(false);}} />
                
                <div className={`h-full rounded-xl text-white gap-2.5 bg-black flex flex-row items-center duration-500 lg:hidden ${(!changeNavBg && pathname === '/') ? 'p-2.5' : 'py-2'}`}>
                    <div onClick={() => {(pathname !== '/') && navigate('/'); setOpenMenu(false);}} className={`h-full text-sm text-center bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Home</div>
                    <div onClick={() => navigate('/charts')} className={`h-full text-sm text-center bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Charts</div>
                    <div onClick={() => navigate('/fairprice')} className={`h-full text-sm text-center bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Options Pricing</div>
                    <div onClick={() => navigate('/arbitrage')} className={`h-full text-sm text-center bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Options Arbitrage</div>
                    <div onClick={() => window.open('https://www.croxroad.co/', '_blank')} className={`h-full text-sm text-center bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Blog</div>
                    <div onClick={() => {(pathname !== '/contact') && navigate('/contact'); setOpenMenu(false);}} className={`h-full text-sm text-center bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Contact</div>
                    {(window.localStorage.getItem('token')) ? (
                        <div onClick={() => {window.localStorage.removeItem('token'); window.localStorage.removeItem('username'); window.localStorage.removeItem('subscribe_email'); window.location.reload();}} className={`h-full text-base text-center bg-red-500 text-white rounded-md font-semibold px-2 xl:px-1 flex items-center justify-center hover:bg-red-300 hover:text-black cursor-pointer duration-200`}>LOGOUT</div>
                    ) : (
                        <div onClick={() => {(pathname !== '/login') && navigate('/login'); setOpenMenu(false);}} className={`h-full text-base text-center bg-yellow-400 text-black rounded-md font-semibold px-2 xl:px-1 flex items-center justify-center hover:bg-yellow-100 hover:text-black cursor-pointer duration-200`}>LOGIN</div>
                    )}
                </div>

                <div className={`h-full rounded-xl text-white gap-2.5 bg-black hidden flex-row items-center duration-500 lg:flex ${(!changeNavBg && pathname === '/') ? 'p-2.5' : 'py-2'}`}>
                    <div onClick={() => setOpenMenu(prev => !prev)} className={`h-full text-base text-center bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Menu</div>
                    {(window.localStorage.getItem('token')) ? (
                        <div onClick={() => {window.localStorage.removeItem('token'); window.localStorage.removeItem('username'); window.localStorage.removeItem('subscribe_email'); window.location.reload();}} className={`h-full text-base text-center bg-red-500 text-white rounded-md font-semibold px-2 xl:px-1 flex items-center justify-center hover:bg-red-300 hover:text-black cursor-pointer duration-200 sm:hidden`}>LOGOUT</div>
                    ) : (
                        <div onClick={() => {(pathname !== '/login') && navigate('/login'); setOpenMenu(false);}} className={`h-full text-base text-center bg-yellow-400 text-black rounded-md font-semibold px-2 xl:px-1 flex items-center justify-center hover:bg-yellow-100 hover:text-black cursor-pointer duration-200 sm:hidden`}>LOGIN</div>
                    )}
                </div>

                <div className={`absolute top-20 h-fit ${(!changeNavBg && pathname === '/') ? 'w-[calc(100%-40px)] rounded-xl sm:w-[calc(100%-20px)]' : 'w-full rounded-none left-0 px-5'} text-white p-2.5 gap-2.5 bg-black hidden flex-col items-center transform duration-200 origin-top ${(openMenu) ? 'scale-y-100' : 'scale-y-0'} lg:flex`}>
                    <div onClick={() => {(pathname !== '/') && navigate('/'); setOpenMenu(false);}} className={`h-10 w-full text-base bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 text-center flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Home</div>
                    <div onClick={() => navigate('/charts')} className={`h-10 w-full text-base bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 text-center flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Charts</div>
                    <div onClick={() => navigate('/fairprice')} className={`h-10 w-full text-base bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 text-center flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Options Pricing</div>
                    <div onClick={() => navigate('/arbitrage')} className={`h-10 w-full text-base bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 text-center flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Options Arbitrage</div>
                    <div onClick={() => window.open('https://www.croxroad.co/', '_blank')} className={`h-10 w-full text-base bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 text-center flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Blog</div>
                    <div onClick={() => {(pathname !== '/contact') && navigate('/contact'); setOpenMenu(false);}} className={`h-10 w-full text-base bg-[#333333] border-[#555555] border rounded-md px-2 xl:px-1 text-center flex items-center justify-center hover:bg-white hover:text-black cursor-pointer duration-200`}>Contact</div>
                    {(window.localStorage.getItem('token')) ? (
                        <div onClick={() => {window.localStorage.removeItem('token'); window.localStorage.removeItem('username'); window.localStorage.removeItem('subscribe_email'); window.location.reload();}} className={`h-10 w-full text-base bg-red-500 text-white rounded-md font-semibold px-2 xl:px-1 text-center hidden items-center justify-center hover:bg-red-300 hover:text-black cursor-pointer duration-200 sm:flex`}>LOGIN</div>
                    ) : (
                        <div onClick={() => {(pathname !== '/login') && navigate('/login'); setOpenMenu(false);}} className={`h-10 w-full text-base bg-yellow-400 text-black rounded-md font-semibold px-2 xl:px-1 text-center hidden items-center justify-center hover:bg-yellow-100 hover:text-black cursor-pointer duration-200 sm:flex`}>LOGIN</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Navbar