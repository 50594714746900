import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa'

import deribit from '../assets/deribit.png'
import tradingview from '../assets/tradingview.png'
import logo from '../assets/logo.png'

const Contact = ({ setPathname }) => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setPathname(location.pathname);
  }, [])
  
  return (
    <>
      <div className='h-full w-full pt-[80px] text-sm flex flex-col justify-between'>
        {/* register */}
        <div className='z-10 bg-black h-full w-full p-5 flex justify-center sm:p-2.5 login:h-fit'>
          <div className='h-full w-full max-w-[800px] flex flex-col items-center justify-center gap-2 login:h-fit'>
            <p className='text-xl font-semibold text-center'>Have Questions or Feedback?</p>
            <p className='text-xl font-semibold text-center'>We're here to help</p>

            <div className='h-fit w-full bg-[#222222] p-5 rounded-xl grid grid-cols-2 gap-5 lg:grid-cols-1 sm:p-2.5'>
              <div className='h-full w-full flex items-center justify-center lg:hidden'>
                <img src={logo} alt="logo" className='max-h-[200px] brightness-150' />
              </div>
              
              <div className='h-full w-full flex flex-col justify-center gap-5'>
                <input type="text" placeholder='Your Name' className='h-[40px] w-full bg-transparent border border-[#555555] rounded-md px-2.5'/>
                <input type="text" placeholder='Your Email' className='h-[40px] w-full bg-transparent border border-[#555555] rounded-md px-2.5'/>
                <textarea rows="5" placeholder='Your Message' className='w-full bg-transparent border border-[#555555] rounded-md p-2.5 resize-none'></textarea>
                <button className='h-[50px] bg-yellow-400 text-black text-base font-semibold rounded-md border-2 border-yellow-400 cursor-pointer duration-200 hover:bg-yellow-200/30 hover:text-yellow-400'>SUBMIT</button>
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className='z-10 bg-black h-fit w-full px-5 flex justify-center sm:px-2.5'>
          <div className='relative border-t border-[#555555] h-full w-full max-w-[1440px] py-5 text-sm grid grid-rows-[1fr,auto] md:gap-2.5 sm:py-2.5'>
            <div className='h-full w-full grid grid-cols-[1fr,auto] gap-2.5 md:grid-cols-1 md:gap-1'>
              <div>
                <p className='mb-1'>Data Providers:</p>
                <div className='flex flex-wrap gap-2.5 mb-2 md:mb-0'>
                  <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='bg-[#666666] p-2 rounded-md hover:opacity-50'>
                    <img src={deribit} alt="deribit" className='w-20' />
                  </a>
                  <div className='bg-[#666666] p-2 rounded-md'>
                    <img src={tradingview} alt="tradingview" className='w-20' />
                  </div>
                </div>

                <p className='md:hidden'>Options<span className='text-yellow-400'>Crypto</span>Trade - All rights reserved.</p>
              </div>

              <div>
                <div>
                  <div className='my-1 cursor-pointer underline underline-offset-2 duration-200 hover:text-blue-400 hover:underline-offset-4' onClick={() => navigate('/policy')}>Privacy Policy</div>
                  <p>Follow us:</p>
                  <div className='flex flex-wrap gap-5'>
                    <FaFacebookF size={20} className='fill-white cursor-pointer duration-200 hover:fill-blue-400' />
                    <FaInstagram size={20} className='fill-white cursor-pointer duration-200 hover:fill-blue-400' />
                    <FaTwitter size={20} className='fill-white cursor-pointer duration-200 hover:fill-blue-400' />
                  </div>
                </div>
              </div>
            </div>

            <div className='hidden flex-col items-center md:flex'>
              <p className='text-center'>Options<span className='text-yellow-400'>Crypto</span>Trade - All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact