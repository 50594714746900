import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import RegisterReusable from './RegisterReusable'
import useFetchArbitrage from '../hooks/useFetchArbitrage'

const Arbitrage = ({ setPathname }) => {
  const location = useLocation()
  const priceRef = useRef()

  const [currency, setCurrency] = useState('BTC')
  const [date, setDate] = useState(moment().add(1, 'days').format('DDMMMYY').toUpperCase())
  const [expiry, setExpiry] = useState('')
  const [priceIncrease, setPriceIncrease] = useState(true)

  const fetch = useFetchArbitrage({ symbol: currency, date: date })
  const [loading, data] = fetch

  useEffect(() => {
    setPathname(location.pathname);
  }, [])

  useEffect(() => {
    let intervalID;

    intervalID = setInterval(() => {
      const today = moment().utcOffset(8)
      const nextDay = moment(date).utcOffset(8).add(16, 'hours')
      let diff = moment.duration(nextDay.diff(today)).asMilliseconds();
      setExpiry(`${moment.duration(diff).days()}d - ${moment.duration(diff).hours()}h ${moment.duration(diff).minutes()}m ${moment.duration(diff).seconds()}s`)
    }, 100);

    return () => clearInterval(intervalID)
  }, [date])

  useEffect(() => {
    if (data.price > priceRef.current) {
      setPriceIncrease(true)
    } else {
      setPriceIncrease(false)
    }
    
    priceRef.current = data.price
  }, [data.price])
  
  return (
    <div className='fixed top-0 left-[200px] h-full w-[calc(100%-200px)] duration-200 xl:left-0 xl:w-full'>
      <div className='h-[40px] w-full text-xl text-yellow-400 font-semibold px-6 flex items-center'>Options Arbitrage</div>

      <div className={`h-[calc(100%-40px)] w-full flex flex-col px-3 pb-2.5 gap-2.5 overflow-y-scroll`}>
        {(window.localStorage.getItem('subscribe') === 'true') ? <></> : <RegisterReusable/>}

        {/* selection */}
        <div className='h-fit w-full'>
          <div className='h-fit w-full bg-[#222222] rounded-xl text-sm p-3 grid grid-cols-[150px,1fr] gap-5 md:grid-cols-1 md:gap-2.5'>
            <div>
              <p>Select Crypto:</p>
              <select value={currency} onChange={e => setCurrency(e.target.value)} className='h-8 w-full bg-transparent border border-white rounded-md cursor-pointer duration-200 hover:bg-white hover:text-black md:max-w-[150px]'>
                <option className='bg-black' value='BTC'>Bitcoin</option>
                <option className='bg-black' value='ETH'>Ethereum</option>
              </select>
            </div>

            {(loading) ? (
              <div className='relative w-[60px] h-full flex items-center justify-evenly'>
                <div className='w-1/12 bg-white animate-loading-bars' style={{ animationDelay: '-0.24s' }}/>
                <div className='w-1/12 bg-white animate-loading-bars' style={{ animationDelay: '-0.12s' }}/>
                <div className='w-1/12 bg-white animate-loading-bars' style={{ animationDelay: '0' }}/>
              </div>
            ) : (
              <div>
                <p>Select Date:</p>
                <div className='flex flex-wrap items-center gap-2.5'>
                  {data.date.map((e, i) => (
                    <div onClick={() => setDate(e)} key={i} className={`h-8 border rounded-full cursor-pointer flex items-center justify-center px-2 duration-200 hover:bg-white hover:text-black ${(date === e) ? 'border-yellow-400 bg-yellow-400 text-black' : 'border-white bg-transparent'}`}>{e}</div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* table container */}
        <div className='h-fit w-full'>
          <div className='h-fit w-full text-sm bg-[#222222] p-3 rounded-xl grid grid-flow-row'>
            {/* info */}
            <div className='h-fit w-full flex gap-5 mb-2 sm:flex-col sm:gap-2.5'>
              <div className='flex flex-wrap items-center flex-1 justify-end gap-x-5 sm:justify-start'>
                <p><span className='text-yellow-400'>Price:&nbsp;</span><span className={`font-normal ${(priceIncrease) ? 'text-green-400' : 'text-red-400'}`}>$ {data.price.toFixed(2).toLocaleString()}</span></p>
                <p><span className='text-yellow-400'>DVOL:&nbsp;</span><span className='font-normal'>{data.dvol.toFixed(2)}</span></p>
                <p><span className='text-yellow-400'>Expiry:&nbsp;</span><span className='font-normal'>{expiry}</span></p>
              </div>
            </div>

            {(loading) ? (
              <>
                {/* loading skeleton */}
                <div className='h-[200px] w-full animate-pulse rounded-lg grid grid-rows-[40px,1fr]'>
                  <div className='h-full w-full grid grid-cols-2'>
                    <div className='h-full w-full flex items-center justify-center'>
                      <div className='h-3/4 w-11/12 bg-[#555555] rounded-full'/>
                    </div>
                    <div className='h-full w-full flex items-center justify-center'>
                      <div className='h-3/4 w-11/12 bg-[#555555] rounded-full'/>
                    </div>
                  </div>
                  <div className='h-full w-full flex flex-col'>
                    <div className='h-full w-full grid grid-cols-5'>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                    </div>
                    <div className='h-full w-full grid grid-cols-5'>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                    </div>
                    <div className='h-full w-full grid grid-cols-5'>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                      <div className='h-full w-full flex items-center justify-center'>
                        <div className='h-3/4 w-10/12 bg-[#555555] rounded-full'/>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='h-fit min-h-[150px] w-full text-xs border border-[#444444] overflow-hidden'>
                {/* main table */}
                <div className='bg-black overflow-y-scroll'>
                  <table className='w-full'>
                    <thead >
                      <tr className='bg-yellow-200/20'>
                        <th className='h-10 min-w-[80px] w-[120px] text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Alert</th>
                        <th className='h-10 min-w-[80px] text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Synthetic Price</th>
                        <th className='h-10 min-w-[80px] text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>CALL</th>
                        <th className='h-10 min-w-[80px] text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>Strike</th>
                        <th className='h-10 min-w-[80px] text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>PUT</th>
                        <th className='h-10 min-w-[80px] text-center text-yellow-400 font-semibold px-1 border border-[#444444]'>ARB</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.tableData.map((e, i) => (
                        <tr key={i} className='odd:bg-[#222222]'>
                          <td className={`h-10 w-[120px] px-1 text-center border border-[#444444] ${(e.ARB > 0) ? 'text-green-400' : 'text-white'}`}>
                            {(e.ARB > 0) ? (
                              <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline hover:text-blue-500 hover:underline hover:underline-offset-4'>
                                ARBITRAGE ON
                              </a>
                            ) : '-'}
                          </td>
                          <td className={`h-10 px-1 text-center border border-[#444444]`}>{e.sarb.toFixed(0)}</td>
                          <td className='h-10 px-1 text-center border border-[#444444]'>
                            {e.bidCall ? (
                              <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline text-green-400 hover:text-blue-500 hover:underline hover:underline-offset-2'>
                                {e.bidCall}
                              </a>
                            ) : ''}
                            <p>
                              {e.bidCall ? (
                                <>
                                  <span className='text-[#AAAAAA]'>$&nbsp;</span>
                                  {(e.bidCall * data.price).toFixed(2)}
                                </>
                              ) : (
                                <span className='text-green-400'>-</span>
                              )}
                            </p>
                          </td>
                          <td className='h-10 px-1 text-center bg-black'>{e.strike}</td>
                          <td className='h-10 px-1 text-center border border-[#444444]'>
                            {e.askPut ? (
                              <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline text-red-400 hover:text-blue-500 hover:underline hover:underline-offset-2'>
                                {e.askPut}
                              </a>
                            ) : ''}
                            <p>
                              {e.askPut ? (
                                <>
                                  <span className='text-[#AAAAAA]'>$&nbsp;</span>
                                  {(e.askPut * data.price).toFixed(2)}
                                </>
                              ) : (
                                <span className='text-red-400'>-</span>
                              )}
                            </p>
                          </td>
                          <td className={`h-10 px-1 text-center border border-[#444444] ${(e.ARB > 0) ? 'text-green-400' : 'text-red-400'}`}>
                            <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='no-underline hover:text-blue-500 hover:underline hover:underline-offset-4'>
                              {e.ARB.toFixed(2)}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* theory */}
        <div className='h-fit w-full'>
          <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-3 flex flex-col gap-2'>
            <p className='text-lg font-semibold'>What is Arbitrage?</p>
            <p className='text-sm text-[#CCCCCC]'>Arbitrage trading is a trading method with much lower risks and stable returns than unilateral trading. Low risk of carry trades Carry trade portfolios are generally market-neutral or close to market-neutral.</p>
            <p className='text-sm text-[#CCCCCC]'>Market neutrality means that in portfolio management, most or even all of the market risk of the portfolio is eliminated by means of hedging and other means by simultaneously constructing buying and selling positions, so that the return of the portfolio is only related to its own characteristics, and not affected by it. The impact of overall market movements.</p>
            <p className='text-sm text-[#CCCCCC]'>Arbitrage is a profitable trading method whether in a bear market or a bull market. Since carry trades focus on changes in relative price levels between two financial assets, rather than absolute price levels, carry trade yields are not affected by whether the broader market rises or falls. In fact, according to the data of foreign and domestic futures markets, the profit of arbitrage trading is often proportional to the volatility of the market.</p>
            <p className='text-sm text-[#CCCCCC]'>A more attractive risk/reward ratio an arbitrage position can provide a more attractive risk/reward ratio relative to a given one-sided position. Although the profit per carry trade is not very high, the success rate is high, which is the benefit of the limited risk of the spread and the lower volatility characteristics. In the long run, only a small number of people who make unilateral transactions make a profit, it has the characteristics of stable income and low risk, so it has a more attractive return/risk ratio. In the process of fierce competition between long and short parties holding unilateral positions, arbitrageurs can often choose to intervene and make profits easily.</p>
          </div>
        </div>

        {/* theory */}
        <div className='h-fit w-full'>
          <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-3 flex flex-col gap-2'>
            <p className='text-lg font-semibold'>What is "Synthetic Price"?</p>
            <p className='text-sm text-[#CCCCCC]'>In put-call parity, the synthetic price is the price of a synthetic option, which is created by holding a long call option and a short put option on the same underlying asset, with the same strike price and expiration date. The synthetic price is equal to the price of the underlying asset plus the present value of the strike price.</p>
            <br/>
            <p className='text-sm text-[#CCCCCC]'>The put-call parity formula is:</p>
            <p className='text-sm text-white font-semibold'>C + PV(K) = P + S</p>
            <p className='text-sm text-[#CCCCCC]'>where:</p>
            <p className='text-sm text-[#CCCCCC]'><span className='text-white font-semibold'>C</span> is the price of the call option</p>
            <p className='text-sm text-[#CCCCCC]'><span className='text-white font-semibold'>P</span> is the price of the put option</p>
            <p className='text-sm text-[#CCCCCC]'><span className='text-white font-semibold'>K</span> is the strike price</p>
            <p className='text-sm text-[#CCCCCC]'><span className='text-white font-semibold'>PV(K)</span> is the present value of the strike price</p>
            <p className='text-sm text-[#CCCCCC]'><span className='text-white font-semibold'>S</span> is the price of the underlying asset</p>
            <br/>
            <p className='text-sm text-[#CCCCCC]'>The put-call parity formula shows that the prices of call and put options are always related to the price of the underlying asset. If the prices of call and put options are not equal, then there is an arbitrage opportunity. An arbitrageur can buy the undervalued option and sell the overvalued option, making a risk-free profit.</p>
            <p className='text-sm text-[#CCCCCC]'>The put-call parity formula can be used to create synthetic options. For example, if an investor believes that the price of a call option is too high, they can create a synthetic call option by buying a put option and shorting the underlying asset. This will create a position that has the same payoff as a call option, but at a lower cost.</p>
            <p className='text-sm text-[#CCCCCC]'>The put-call parity formula is a powerful tool that can be used to understand the relationship between call and put options, and to create synthetic options.</p>
          </div>
        </div>

        {/* theory */}
        <div className='h-fit w-full'>
          <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-3 flex flex-col gap-2'>
            <p className='text-lg font-semibold'>How to execute a put-call parity arbitrage alert?</p>
            <ul className='list-decimal list-inside'>
              <li className='text-sm text-[#CCCCCC]'>Identify an arbitrage opportunity displayed in the table above. This means our algorithm found a situation where the current synthetic price of the put-call options is higher than the current price.</li>
              <li className='text-sm text-[#CCCCCC]'>Enter into the trade. This would involve buying the call option and selling the put option. You would also need to short the current price of the underlying asset.</li>
              <li className='text-sm text-[#CCCCCC]'>Close out the trade. Upon expiration you let both put and call options expire and you also need to close the short position by buying the underlying asset.</li>
            </ul>
            <p className='text-sm text-[#CCCCCC]'>So in this scenario no matter how up or down the price have moved, the combination of these 3 part trades will close in profits</p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Arbitrage