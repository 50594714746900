import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Home from './components/Home';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Login from './components/Login';
import Register from './components/Register';
import Forgot from './components/Forgot';
import Charts from './components/Charts';
import FairPrice from './components/FairPrice';
import Arbitrage from './components/Arbitrage';
// import Blog from './components/Blog'
// import BlogContent from './components/BlogContent'
import Contact from './components/Contact';
import Policy from './components/Policy';
import ThankyouRegister from './components/ThankyouRegister';
import ThankyouSubscription from './components/ThankyouSubscription';

function App() {
  const[changeNavBg, setChangeNavBg] = useState(false)
  const[pathname, setPathname] = useState('/')

  const handleScroll = (e) => {
    const scrollHeight = e.target.scrollTop
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth

    if (windowWidth < 300) {
      if (scrollHeight > 220) {
        setChangeNavBg(true)
      } else {
        setChangeNavBg(false)
      }
    } else if (windowWidth >= 300 && windowWidth < 520) {
      if (scrollHeight > 340) {
        setChangeNavBg(true)
      } else {
        setChangeNavBg(false)
      }
    } else if (windowHeight < 420) {
      if (scrollHeight > 340) {
        setChangeNavBg(true)
      } else {
        setChangeNavBg(false)
      }
    } else {
      if (scrollHeight > windowHeight - 80) {
        setChangeNavBg(true)
      } else {
        setChangeNavBg(false)
      }
    }
  }
  
  return (
    <div className='fixed h-full w-full bg-black'>
      <div className='relative h-full w-full overflow-y-scroll custom-scrollbar-design' onScroll={e => handleScroll(e)}>
        <Router>
          {(pathname === '/' || pathname === '/login' || pathname === '/register' || pathname === '/forgot' || pathname === '/blog' || pathname === '/blogcontent' || pathname === '/contact' || pathname === '/policy') ? (
            <Navbar changeNavBg={changeNavBg} pathname={pathname}/>
          ) : (
            (pathname === '/charts' || pathname === '/fairprice' || pathname === '/arbitrage') ? (
              <Sidebar pathname={pathname}/>
            ) : (
              <></>
            )
          )}
          
          <Routes>
            <Route exact path='/' element={<Home setPathname={setPathname}/>}/>
            <Route exact path='/login' element={<Login setPathname={setPathname}/>}/>
            <Route exact path='/register' element={<Register setPathname={setPathname}/>}/>
            <Route exact path='/forgot' element={<Forgot setPathname={setPathname}/>}/>
            <Route exact path='/charts' element={<Charts setPathname={setPathname}/>}/>
            <Route exact path='/fairprice' element={<FairPrice setPathname={setPathname}/>}/>
            <Route exact path='/arbitrage' element={<Arbitrage setPathname={setPathname}/>}/>
            {/* <Route exact path='/blog' element={<Blog setPathname={setPathname}/>}/>
            <Route exact path='/blogcontent' element={<BlogContent setPathname={setPathname}/>}/> */}
            <Route exact path='/contact' element={<Contact setPathname={setPathname}/>}/>
            <Route exact path='/policy' element={<Policy setPathname={setPathname}/>}/>
            <Route exact path='/thankyou_reg/:token' element={<ThankyouRegister setPathname={setPathname}/>}/>
            <Route exact path='/thankyou_sub/:email' element={<ThankyouSubscription setPathname={setPathname}/>}/>
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
