import React, { useEffect } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import PixelSubscription from '../pixel/PixelSubscription'

const ThankyouSubscription = ({ setPathname }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    useEffect(() => {
        setPathname(location.pathname);
        if (params.email !== window.localStorage.getItem('subscribe_email')) {
            navigate('/')
        }
    }, [])

    return (
        <div className="relative h-full w-full p-5 flex flex-col items-center justify-center overflow-hidden">
            <PixelSubscription/>
            <div className='h-full w-full max-w-[1440px] flex flex-col items-center justify-center'>
                <p className='z-10 text-8xl text-clip text-transparent bg-clip-text bg-gradient-to-t from-sky-500 to-sky-900 brightness-200 xxl:text-[7vw] lg:text-[40px] sm:text-[30px]' style={{ fontFamily: 'Rubik Distressed, cursive' }}>CROXPOW</p>
                <p className='z-10 text-9xl leading-normal text-white text-center font-semibold xxl:text-[10vw] lg:text-[50px] md:leading-snug sm:text-[40px]'>Thank You</p>
                <p className='z-10 text-2xl leading-normal text-white text-center font-semibold mb-[20px] xxl:text-[2vw] lg:text-[2vw] md:text-[12px] md:leading-snug sm:text-[10px]'>You have successfully subscribed to the CROXPOW Pro.</p>
                <button onClick={() => navigate('/charts')} className='text-black text-base font-semibold bg-yellow-400 h-12 w-fit px-2.5 rounded-lg hover:shadow-[0_0_10px_0_#FCE073] duration-200'>START STRATEGIZING</button>
            </div>
        </div>
    )
}

export default ThankyouSubscription