import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Policy = ({ setPathname }) => {
  const location = useLocation()

  useEffect(() => {
    setPathname(location.pathname);
  }, [])

  return (
    <div className='fixed top-[80px] left-0 h-full w-full flex flex-col items-center'>
      <div className={`h-[calc(100%-80px)] w-full max-w-[1200px] flex flex-col p-5 gap-2.5 overflow-y-scroll sm:px-2.5`}>
        <div className='w-full px-5 flex flex-col items-center justify-center'>
          <p className='bg-clip-text text-transparent bg-gradient-to-r from-yellow-100 to-yellow-500 text-3xl font-semibold sm:text-xl'>CROXPOW</p>
          <p className='text-xl font-semibold sm:text-lg'>Privacy Policy</p>
        </div>

        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>Intro</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>At CROXPOW, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by CROXPOW and how we use it.</p>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in CROXPOW. This policy is not applicable to any information collected offline or via channels other than this website.</p>
          </div>
        </div>
        
        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>Consent</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
          </div>
        </div>
        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>Information we collect</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
          </div>
        </div>
        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>How we use your information</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>We use the information we collect in various ways, including to:</p>
            <ul className='list-disc list-inside'>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>Provide, operate, and maintain our website.</li>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>Improve, personalize, and expand our website.</li>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>Understand and analyze how you use our website.</li>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>Develop new products, services, features, and functionality.</li>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.</li>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>Send you emails.</li>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>Find and prevent fraud.</li>
            </ul>
          </div>
        </div>
        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>Log Files</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>CROXPOW follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.</p>
          </div>
        </div>
        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>Cookies and Web Beacons</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>Like any other website, CROXPOW uses ‘cookies’. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.</p>
          </div>
        </div>
        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>Google DoubleClick DART Cookie</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to our site and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – <a href="https://policies.google.com/technologies/ads" target='_blank' className='break-all text-blue-400 underline underline-offset-2 cursor-pointer duration-200 hover:text-purple-400 hover:underline-offset-4'>https://policies.google.com/technologies/ads</a></p>
          </div>
        </div>
        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>Our Advertising Partners</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>
            <ul className='list-disc list-inside'>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>Google <a href="https://policies.google.com/technologies/ads" target='_blank' className='break-all text-blue-400 underline underline-offset-2 cursor-pointer duration-200 hover:text-purple-400 hover:underline-offset-4'>https://policies.google.com/technologies/ads</a></li>
            </ul>
          </div>
        </div>
        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>Advertising Partners Privacy Policies</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>You may consult this list to find the Privacy Policy for each of the advertising partners of CROXPOW.</p>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on CROXPOW, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>Note that CROXPOW has no access to or control over these cookies that are used by third-party advertisers.</p>
          </div>
        </div>
        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>Third Party Privacy Policies</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>CROXPOW’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.</p>
          </div>
        </div>
        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>CCPA Privacy Rights (Do Not Sell My Personal Information)</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>Under the CCPA, among other rights, California consumers have the right to:</p>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>Request that a business that collects a consumer’s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>Request that a business delete any personal data about the consumer that a business has collected.</p>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>Request that a business that sells a consumer’s personal data, not sell the consumer’s personal data.</p>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
          </div>
        </div>
        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>GDPR Data Protection Rights</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
            <ul className='list-disc list-inside'>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</li>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
              <li className='text-base text-[#CCCCCC] sm:text-sm'>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
            </ul>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
          </div>
        </div>
        <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-2.5'>
          <p className='text-lg font-semibold sm:text-base'>Children’s Information</p>
          <div className='grid grid-flow-row gap-2'>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
            <p className='text-base text-[#CCCCCC] sm:text-sm'>CROXPOW does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Policy