import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

import { host } from './share'

const useFetchCharts = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({ price: [], volume: [], turnover: [], openInterest: [] })

    const fetchValues = () => {
        const cancelToken = axios.CancelToken.source()
        const url = `http://${host}:5001/v2/getChart`;
    
        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Content-Type": "application/json",
                "x-access-token": (window.localStorage.getItem('token')) ? window.localStorage.getItem('token') : "N"
            },
        },
        {
            cancelToken: cancelToken.token
        })
        .then((response) => {
            const obj = response.data
            if (obj.result) {
                let temp = obj.result.reverse()
                setData(p => { return {
                    ...p,
                    price: temp.map(e => {return{t: e[0] ? moment(new Date(e[0]*1)).format('DD-MM-YYYY') : '', v: e[4]}}),
                    volume: temp.map(e => {return{t: e[0] ? moment(new Date(e[0]*1)).format('DD-MM-YYYY') : '', v: e[5]}}),
                    turnover: temp.map(e => {return{t: e[0] ? moment(new Date(e[0]*1)).format('DD-MM-YYYY') : '', v: e[6]}})
                }})
            } else {
                setData(p => { return { ...p, price: [], volume: [], turnover: [] } })
            }
            setLoading(false)
        })
        .catch((err) => {
            console.log(err)
            setData(p => { return { ...p, price: [], volume: [], turnover: [] } })
            setLoading(false)
        })
    }

    const fetchOpenInterest = () => {
        const cancelToken = axios.CancelToken.source()
        const url = `http://${host}:5001/v2/getOpenInterest`;
    
        axios.post(url, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, x-access-token",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Content-Type": "application/json",
                "x-access-token": (window.localStorage.getItem('token')) ? window.localStorage.getItem('token') : "N"
            },
        },
        {
            cancelToken: cancelToken.token
        })
        .then((response) => {
            const obj = response.data
            if (obj.result) {
                let temp = obj.result.reverse()

                setData(p => { return {
                    ...p,
                    openInterest: temp.map(e => {return{t: e.timestamp ? moment(new Date(e.timestamp*1)).format('DD-MM-YYYY') : '', v: e.openInterest}}),
                }})
            } else {
                setData(p => { return { ...p, openInterest: [] } })
            }
            setLoading(false)
        })
        .catch((err) => {
            console.log(err)
            setData(p => { return { ...p, openInterest: [] } })
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        fetchValues()
        fetchOpenInterest()
    }, [])
    
    return [loading, data]
}

export default useFetchCharts


// import React, { useState, useEffect } from 'react'
// import axios from 'axios'

// import { host } from './share'

// const useFetchCharts = () => {
//     const [loading, setLoading] = useState(false)
//     const [data, setData] = useState({ price: [], supply: [], profitRatio: [], miningDiff: [] })

//     const fetchProfitRatio = () => {
//         const cancelToken = axios.CancelToken.source()
//         const url = `http://${host}:5001/getChart`;
    
//         axios.post(url, {
//             headers: {
//                 "Access-Control-Allow-Origin": "*",
//                 "Access-Control-Allow-Headers": "Content-Type, x-access-token",
//                 "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
//                 "Content-Type": "application/json",
//                 "x-access-token": (window.localStorage.getItem('token')) ? window.localStorage.getItem('token') : "N"
//             },
//             id: 0
//         },
//         {
//             cancelToken: cancelToken.token
//         })
//         .then((response) => {
//             if (response.data) {
//                 setData(p => { return { ...p, profitRatio: response.data.result.sort((a, b) => new Date(b.t) - new Date(a.t)) } })
//             } else {
//                 setData(p => { return { ...p, profitRatio: [] } })
//             }
//             setLoading(false)
//         })
//         .catch((err) => {
//             console.log(err)
//             setData(p => { return { ...p, profitRatio: [] } })
//             setLoading(false)
//         })
//     }

//     const fetchMiningDiff = () => {
//         const cancelToken = axios.CancelToken.source()
//         const url = `http://${host}:5001/getChart`;
    
//         axios.post(url, {
//             headers: {
//                 "Access-Control-Allow-Origin": "*",
//                 "Access-Control-Allow-Headers": "Content-Type, x-access-token",
//                 "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
//                 "Content-Type": "application/json",
//                 "x-access-token": (window.localStorage.getItem('token')) ? window.localStorage.getItem('token') : "N"
//             },
//             id: 1
//         },
//         {
//             cancelToken: cancelToken.token
//         })
//         .then((response) => {
//             if (response.data) {
//                 setData(p => { return { ...p, miningDiff: response.data.difficulty.sort((a, b) => new Date(b.t) - new Date(a.t)), price: response.data.price.sort((a, b) => new Date(b.t) - new Date(a.t)) } })
//             } else {
//                 setData(p => { return { ...p, miningDiff: [] } })
//             }
//             setLoading(false)
//         })
//         .catch((err) => {
//             console.log(err)
//             setData(p => { return { ...p, miningDiff: [] } })
//             setLoading(false)
//         })
//     }
    
//     const fetchSupply = () => {
//         const cancelToken = axios.CancelToken.source()
//         const url = `http://${host}:5001/getChart`;
    
//         axios.post(url, {
//             headers: {
//                 "Access-Control-Allow-Origin": "*",
//                 "Access-Control-Allow-Headers": "Content-Type, x-access-token",
//                 "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
//                 "Content-Type": "application/json",
//                 "x-access-token": (window.localStorage.getItem('token')) ? window.localStorage.getItem('token') : "N"
//             },
//             id: 2
//         },
//         {
//             cancelToken: cancelToken.token
//         })
//         .then((response) => {
//             if (response.data) {
//                 setData(p => { return { ...p, supply: response.data.circulating_supply.sort((a, b) => new Date(b.t) - new Date(a.t)) } })
//             } else {
//                 setData(p => { return { ...p, supply: [] } })
//             }
//             setLoading(false)
//         })
//         .catch((err) => {
//             console.log(err)
//             setData(p => { return { ...p, supply: [] } })
//             setLoading(false)
//         })
//     }

//     useEffect(() => {
//         setLoading(true)
//         fetchProfitRatio()
//         fetchMiningDiff()
//         fetchSupply()
//     }, [])
    
//     return [loading, data]
// }

// export default useFetchCharts