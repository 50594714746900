import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment/moment'
import Chart from "react-apexcharts";

import RegisterReusable from './RegisterReusable'
import useFetchCharts from '../hooks/useFetchCharts';

const Charts = ({ setPathname }) => {
  const location = useLocation()
  const [period, setPeriod] = useState('1w')
  const [filterData, setFilterData] = useState({ price: [], volume: [], turnover: [], openInterest: [] })
  
  const fetch = useFetchCharts()
  const [loading, data] = fetch

  useEffect(() => {
    setPathname(location.pathname);
  }, [])

  // const filterProfitRatio = (value) => {
  //   let result = []

  //   if (period === '1w') {
  //     result = value.slice(0, 7).map(e => {
  //       return { t: moment(new Date(e.t*1000)).format('MM-DD-YYYY'), v: e.v }
  //     })
  //   }
  //   else if (period === '30d') {
  //     result = value.slice(0, 30).map(e => {
  //       return { t: moment(new Date(e.t*1000)).format('MM-DD-YYYY'), v: e.v }
  //     })
  //   }
  //   else if (period === '3m') {
  //     let sliced = value.slice(0, 90)
  //     let weekly = []
  //     let count = 0
  //     let countWeek = 1

  //     for (let i = 0; i < sliced.length; i++) {
  //       if (count === 7) {
  //         count = 1
  //         countWeek ++
  //         weekly.push({ t: 'W'+countWeek.toString()+', '+moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
  //       } else {
  //         count ++
  //         weekly.push({ t: 'W'+countWeek.toString()+', '+moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
  //       }
  //     }

  //     let dates = Array.from(new Set(weekly.map(e => e.t)))

  //     let final = dates.map(e => {
  //       let temp = weekly.filter(f => f.t === e)
  //       return { t: e, v: temp.map(m => m.v).reduce((a, b) => a + b, 0) / temp.length }
  //     })

  //     result = final
  //   }
  //   else if (period === '6m') {
  //     let sliced = value.slice(0, 180)
  //     let weekly = []
  //     let count = 0
  //     let countWeek = 1

  //     for (let i = 0; i < sliced.length; i++) {
  //       if (count === 7) {
  //         count = 1
  //         countWeek ++
  //         weekly.push({ t: 'W'+countWeek.toString()+', '+moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
  //       } else {
  //         count ++
  //         weekly.push({ t: 'W'+countWeek.toString()+', '+moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
  //       }
  //     }

  //     let dates = Array.from(new Set(weekly.map(e => e.t)))

  //     let final = dates.map(e => {
  //       let temp = weekly.filter(f => f.t === e)
  //       return { t: e, v: temp.map(m => m.v).reduce((a, b) => a + b, 0) / temp.length }
  //     })

  //     result = final
  //   }
  //   else if (period === '1y') {
  //     let sliced = value.slice(0, 365)
  //     let yearly = []

  //     for (let i = 0; i < sliced.length; i++) {
  //       yearly.push({ t: moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
  //     }

  //     let dates = Array.from(new Set(yearly.map(e => e.t)))

  //     let final = dates.map(e => {
  //       let temp = yearly.filter(f => f.t === e)
  //       return { t: e, v: temp.map(m => m.v).reduce((a, b) => a + b, 0) / temp.length }
  //     })

  //     result = final
  //   }
  //   else if (period === '5y') {
  //     let sliced = value.slice(0, 365*5)
  //     let yearly = []

  //     for (let i = 0; i < sliced.length; i++) {
  //       yearly.push({ t: moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
  //     }

  //     let dates = Array.from(new Set(yearly.map(e => e.t)))

  //     let final = dates.map(e => {
  //       let temp = yearly.filter(f => f.t === e)
  //       return { t: e, v: temp.map(m => m.v).reduce((a, b) => a + b, 0) / temp.length }
  //     })

  //     result = final
  //   }
  //   else if (period === 'all') {
  //     let sliced = value
  //     let yearly = []

  //     for (let i = 0; i < sliced.length; i++) {
  //       yearly.push({ t: moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
  //     }

  //     let dates = Array.from(new Set(yearly.map(e => e.t)))

  //     let final = dates.map(e => {
  //       let temp = yearly.filter(f => f.t === e)
  //       return { t: e, v: temp.map(m => m.v).reduce((a, b) => a + b, 0) / temp.length }
  //     })

  //     result = final
  //   }

  //   return result.reverse()
  // }

  const filterProfitRatio = (value) => {
    let result = []

    if (period === '1w') {
      result = value.slice(0, 7)
    }
    else if (period === '30d') {
      result = value.slice(0, 30)
    }
    else if (period === '3m') {
      result = value.slice(0, 90)
    }
    else if (period === '6m') {
      result = value
    }

    return result
  }

  useEffect(() => {
    setFilterData({ price: filterProfitRatio(data.price), volume: filterProfitRatio(data.volume), turnover: filterProfitRatio(data.turnover), openInterest: filterProfitRatio(data.openInterest) })
  }, [period, data])

  const seriesPrice = [
    {
      name: 'Price(USD)',
      data: filterData.price.map(e => e.v ? (e.v*1).toFixed(1) : e.v)
    }
  ]

  const optionPrice = {
    chart: {
      background: '#222222',
      toolbar: {
        tools: {
          download: true,
          selection: false,
          pan: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          reset: true
        }
      }
    },
    colors: ['#00E396'],
    title: {
      text: 'Bitcoin: Price History (USD)',
      style: {
          color: '#FFFFFF',
          fontSize: '14px',
          fontWeight: 600,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      type: 'category',
      categories: filterData.price.map(e => e.t),
      labels: { style: { colors: '#FFFFFF', fontSize: '10px', hideOverlappingLabels: true, trim: true } }
    },
    yaxis: {
      labels: {
        style: { colors: '#FFFFFF' }
      },
    },
    tooltip: {
      theme: 'dark',
    },
    grid: {
      show: true,
      borderColor: 'rgba(255, 255, 255, 0.2)',
      position: 'back',
    }
  }

  // const getSupplyPrice = () => {
  //   let supply = filterData.supply.map(e => e.v ? e.v.toFixed(1) : e.v)
  //   let price = filterData.price.map(e => e.v ? e.v.toFixed(1) : e.v)
  //   let temp = []
    
  //   for (let i = 0; i < supply.length; i++) {
  //     if (price[i]) {
  //       temp.push(price[i]*1)
  //     } else {
  //       temp.unshift(0)
  //     }
  //   }

  //   return temp
  // }

  const seriesVolume = [
    {
      name: 'Volume',
      data: filterData.volume.map(e => e.v ? (e.v*1).toFixed(0) : e.v),
      type: 'bar'
    },
    {
      name: 'Price(USD)',
      data: filterData.price.map(e => e.v ? (e.v*1).toFixed(1) : e.v),
      type: 'line'
    }
  ]

  const optionVolume = {
    chart: {
      background: '#222222',
      toolbar: {
        tools: {
          download: true,
          selection: false,
          pan: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          reset: true
        }
      }
    },
    colors: ['#008FFB', '#00E396'],
    legend: {
      labels: {
        colors: '#FFFFFF'
      }
    },
    title: {
      text: 'Bitcoin: Volume',
      style: {
          color: '#FFFFFF',
          fontSize: '14px',
          fontWeight: 600,
      },
    },
    stroke: {
      curve: 'smooth',
      width: [1, 2]
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'category',
      categories: filterData.volume.map(e => e.t),
      labels: { style: { colors: '#FFFFFF', fontSize: '10px', hideOverlappingLabels: true, trim: true } }
    },
    yaxis: [{
      labels: {
        style: { colors: '#FFFFFF' }
      },
    }, {
      opposite: true,
      labels: {
        style: { colors: '#FFFFFF' }
      },
      max: () => {
        let max = filterData.price.map(e => e.v*1)
        return Math.max(...max)
      },
      min: () => {
        let min = filterData.price.map(e => e.v*1)
        return Math.min(...min)
      },
    }],
    tooltip: {
      theme: 'dark',
    },
    grid: {
      show: true,
      borderColor: 'rgba(255, 255, 255, 0.2)',
      position: 'back',
    }
  }

  // const getMiningDiff = () => {
  //   let supply = filterData.miningDiff.map(e => e.v ? e.v.toFixed(1) : e.v)
  //   let price = filterData.price.map(e => e.v ? e.v.toFixed(1) : e.v)
  //   let temp = []
    
  //   for (let i = 0; i < supply.length; i++) {
  //     if (price[i]) {
  //       temp.push(price[i]*1)
  //     } else {
  //       temp.unshift(0)
  //     }
  //   }

  //   return temp
  // }

  const seriesTurnover = [
    {
      name: 'Turnover',
      data: filterData.turnover.map(e => e.v ? (e.v*1).toFixed(1) : e.v),
      type: 'bar'
    },
    {
      name: 'Price(USD)',
      data: filterData.price.map(e => e.v ? (e.v*1).toFixed(1) : e.v),
      type: 'line'
    }
  ]

  const optionTurnover = {
    chart: {
      background: '#222222',
      toolbar: {
        tools: {
          download: true,
          selection: false,
          pan: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          reset: true
        }
      }
    },
    colors: ['#FFC000', '#00E396'],
    dataLabels: {
      enabled: false
    },
    title: {
      text: 'Bitcoin: Turnover',
      style: {
          color: '#FFFFFF',
          fontSize: '14px',
          fontWeight: 600,
      },
    },
    legend: {
      labels: {
        colors: '#FFFFFF'
      }
    },
    stroke: {
      curve: 'smooth',
      width: [1, 2],
    },
    xaxis: {
      type: 'category',
      categories: filterData.turnover.map(e => e.t),
      labels: { style: { colors: '#FFFFFF', fontSize: '10px', hideOverlappingLabels: true, trim: true } }
    },
    yaxis: [{
      labels: {
        style: { colors: '#FFFFFF' }
      },
    }, {
      opposite: true,
      labels: {
        style: { colors: '#FFFFFF' }
      },
      max: () => {
        let max = filterData.price.map(e => e.v*1)
        return Math.max(...max)
      },
      min: () => {
        let min = filterData.price.map(e => e.v*1)
        return Math.min(...min)
      },
    }],
    tooltip: {
      theme: 'dark',
    },
    grid: {
      show: true,
      borderColor: 'rgba(255, 255, 255, 0.2)',
      position: 'back',
    }
  }

  const seriesOpenInterest = [
    {
      name: 'Open Interest',
      data: filterData.openInterest.map(e => e.v ? (e.v*1).toFixed(1) : e.v)
    }
  ]

  const optionOpenInterest = {
    chart: {
      background: '#222222',
      toolbar: {
        tools: {
          download: true,
          selection: false,
          pan: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          reset: true
        }
      }
    },
    colors: ['#FF9800'],
    title: {
      text: 'Bitcoin: Open Interest (last 50 Days max)',
      style: {
          color: '#FFFFFF',
          fontSize: '14px',
          fontWeight: 600,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      type: 'category',
      categories: filterData.openInterest.map(e => e.t),
      labels: { style: { colors: '#FFFFFF', fontSize: '10px', hideOverlappingLabels: true, trim: true } }
    },
    yaxis: {
      labels: {
        style: { colors: '#FFFFFF' }
      }
    },
    tooltip: {
      theme: 'dark',
    },
    grid: {
      show: true,
      borderColor: 'rgba(255, 255, 255, 0.2)',
      position: 'back',
    }
  }

  return (
    <div className='fixed top-0 left-[200px] h-full w-[calc(100%-200px)] duration-200 xl:left-0 xl:w-full'>
      <div className='h-[40px] w-full text-xl text-yellow-400 font-semibold px-6 flex items-center'>Charts</div>
      
      <div className={`h-[calc(100%-40px)] w-full flex flex-col px-3 pb-2.5 gap-2.5 overflow-y-scroll`}>
      {(window.localStorage.getItem('subscribe') === 'true') ? <></> : <RegisterReusable/>}

        {/* selection */}
        <div className='h-fit w-full sticky top-0 z-50'>
          <div className='h-fit w-full bg-[#222222] rounded-xl text-sm p-3 gap-5 flex items-center flex-wrap md:gap-2 border border-[#555]'>
            <p className='text-[#AAAAAA]'>Select Time Period:</p>
            <button onClick={() => setPeriod('1w')} className={`${(period === '1w') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>1 week</button>
            <button onClick={() => setPeriod('30d')} className={`${(period === '30d') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>30 days</button>
            <button onClick={() => setPeriod('3m')} className={`${(period === '3m') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>3 months</button>
            <button onClick={() => setPeriod('6m')} className={`${(period === '6m') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>6 months</button>
            {/* <button onClick={() => setPeriod('1y')} className={`${(period === '1y') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>1 year</button>
            <button onClick={() => setPeriod('5y')} className={`${(period === '5y') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>5 years</button>
            <button onClick={() => setPeriod('all')} className={`${(period === 'all') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>LifeTime</button> */}
          </div>
        </div>
        
        {/* charts */}
        <div className='h-fit w-full grid grid-flow-row gap-2.5'>
          <div className='h-fit w-full grid grid-cols-2 gap-2.5 xxl:grid-cols-1'>
            <div className={`h-full w-full bg-[#222222] p-2.5 rounded-xl ${(loading) ? 'animate-pulse' : ''}`}>
              <Chart type="area" height="400" series={seriesOpenInterest} options={optionOpenInterest}/>
            </div>
            <div className={`h-full w-full bg-[#222222] p-2.5 rounded-xl ${(loading) ? 'animate-pulse' : ''}`}>
              <Chart type="area" height="400" series={seriesPrice} options={optionPrice}/>
            </div>
          </div>
          <div className='h-fit w-full grid grid-cols-2 gap-2.5 xxl:grid-cols-1'>
            <div className={`h-full w-full bg-[#222222] p-2.5 rounded-xl ${(loading) ? 'animate-pulse' : ''}`}>
              <Chart type="area" height="400" series={seriesVolume} options={optionVolume}/>
            </div>
            <div className={`h-full w-full bg-[#222222] p-2.5 rounded-xl ${(loading) ? 'animate-pulse' : ''}`}>
              <Chart type="area" height="400" series={seriesTurnover} options={optionTurnover}/>
            </div>
          </div>
        </div>

        {/* theory */}
        <div className='h-fit w-full'>
          <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-3 flex flex-col gap-2'>
            <p className='text-md font-semibold'>What are these charts for?</p>
            <p className='text-sm text-[#CCCCCC]'>We got you these 4 different charts with different kind of information on Bitcoin.</p>
            <hr className='border-[#555555]'/>
            <p className='text-md font-semibold'>Bitcoin: Open Interest</p>
            <p className='text-sm text-[#CCCCCC]'>It refers to the total number of outstanding Bitcoin futures or options contracts in the market. It is a measure of the amount of money invested in Bitcoin derivatives at any given time.</p>
            <hr className='border-[#555555]'/>
            <p className='text-md font-semibold'>Bitcoin: Price History</p>
            <p className='text-sm text-[#CCCCCC]'>The history of Bitcoin price.</p>
            <hr className='border-[#555555]'/>
            <p className='text-md font-semibold'>Bitcoin: Volume</p>
            <p className='text-sm text-[#CCCCCC]'>The cumulative sum of crypto being bought and sold on the market over a period of time.</p>
            <hr className='border-[#555555]'/>
            <p className='text-md font-semibold'>Bitcoin: Turnover</p>
            <p className='text-sm text-[#CCCCCC]'>The total value of money of all executed transactions in a given time period.</p>
            <hr className='border-[#555555]'/>
          </div>
        </div>

        {/* <div className='h-fit w-full'>
          <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-3 flex flex-col gap-2'>
            <p className='text-md font-semibold'>What are these charts for?</p>
            <p className='text-sm text-[#CCCCCC]'>We got you these 4 different charts with different kind of information.</p>
            <hr className='border-[#555555]'/>
            <p className='text-md font-semibold'>Bitcoin: Price History</p>
            <p className='text-sm text-[#CCCCCC]'>The whole history of Bitcoin price.</p>
            <hr className='border-[#555555]'/>
            <p className='text-md font-semibold'>Bitcoin: Circulating Supply [BTC]</p>
            <p className='text-sm text-[#CCCCCC]'>The total amount of all coins ever created/issued, i.e. the circulating supply.</p>
            <hr className='border-[#555555]'/>
            <p className='text-md font-semibold'>Bitcoin: Mining Difficulty</p>
            <p className='text-sm text-[#CCCCCC]'>The current estimated number of hashes required to mine a block. Note: Bitcoin difficulty is often denoted as the relative difficulty with respect to the genesis block, which required approximately 2^32 hashes. For better comparison across blockchains, our values are denoted in raw hashes.</p>
            <hr className='border-[#555555]'/>
            <p className='text-md font-semibold'>The Spent Output Profit Ratio</p>
            <p className='text-sm text-[#CCCCCC]'>The Spent Output Profit Ratio (SOPR) is computed by dividing the realized value (in USD) divided by the value at creation (USD) of a spent output. Or simply: price sold / price paid. This metric was created by Renato Shirakashi. For a detailed commentary see this post.</p>
            <hr className='border-[#555555]'/>
          </div>
        </div> */}

      </div>
    </div>
  )
}

export default Charts


// import React, { useState, useEffect } from 'react'
// import { useLocation } from 'react-router-dom'
// import moment from 'moment/moment'
// import Chart from "react-apexcharts";

// import RegisterReusable from './RegisterReusable'
// import useFetchCharts from '../hooks/useFetchCharts';

// const Charts = ({ setPathname }) => {
//   const location = useLocation()
//   const [period, setPeriod] = useState('1w')
//   const [filterData, setFilterData] = useState({ price: [], supply: [], profitRatio: [], miningDiff: [] })
  
//   const fetch = useFetchCharts()
//   const [loading, data] = fetch

//   useEffect(() => {
//     setPathname(location.pathname);
//   }, [])

//   const filterProfitRatio = (value) => {
//     let result = []

//     if (period === '1w') {
//       result = value.slice(0, 7).map(e => {
//         return { t: moment(new Date(e.t*1000)).format('MM-DD-YYYY'), v: e.v }
//       })
//     }
//     else if (period === '30d') {
//       result = value.slice(0, 30).map(e => {
//         return { t: moment(new Date(e.t*1000)).format('MM-DD-YYYY'), v: e.v }
//       })
//     }
//     else if (period === '3m') {
//       let sliced = value.slice(0, 90)
//       let weekly = []
//       let count = 0
//       let countWeek = 1

//       for (let i = 0; i < sliced.length; i++) {
//         if (count === 7) {
//           count = 1
//           countWeek ++
//           weekly.push({ t: 'W'+countWeek.toString()+', '+moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
//         } else {
//           count ++
//           weekly.push({ t: 'W'+countWeek.toString()+', '+moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
//         }
//       }

//       let dates = Array.from(new Set(weekly.map(e => e.t)))

//       let final = dates.map(e => {
//         let temp = weekly.filter(f => f.t === e)
//         return { t: e, v: temp.map(m => m.v).reduce((a, b) => a + b, 0) / temp.length }
//       })

//       result = final
//     }
//     else if (period === '6m') {
//       let sliced = value.slice(0, 180)
//       let weekly = []
//       let count = 0
//       let countWeek = 1

//       for (let i = 0; i < sliced.length; i++) {
//         if (count === 7) {
//           count = 1
//           countWeek ++
//           weekly.push({ t: 'W'+countWeek.toString()+', '+moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
//         } else {
//           count ++
//           weekly.push({ t: 'W'+countWeek.toString()+', '+moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
//         }
//       }

//       let dates = Array.from(new Set(weekly.map(e => e.t)))

//       let final = dates.map(e => {
//         let temp = weekly.filter(f => f.t === e)
//         return { t: e, v: temp.map(m => m.v).reduce((a, b) => a + b, 0) / temp.length }
//       })

//       result = final
//     }
//     else if (period === '1y') {
//       let sliced = value.slice(0, 365)
//       let yearly = []

//       for (let i = 0; i < sliced.length; i++) {
//         yearly.push({ t: moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
//       }

//       let dates = Array.from(new Set(yearly.map(e => e.t)))

//       let final = dates.map(e => {
//         let temp = yearly.filter(f => f.t === e)
//         return { t: e, v: temp.map(m => m.v).reduce((a, b) => a + b, 0) / temp.length }
//       })

//       result = final
//     }
//     else if (period === '5y') {
//       let sliced = value.slice(0, 365*5)
//       let yearly = []

//       for (let i = 0; i < sliced.length; i++) {
//         yearly.push({ t: moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
//       }

//       let dates = Array.from(new Set(yearly.map(e => e.t)))

//       let final = dates.map(e => {
//         let temp = yearly.filter(f => f.t === e)
//         return { t: e, v: temp.map(m => m.v).reduce((a, b) => a + b, 0) / temp.length }
//       })

//       result = final
//     }
//     else if (period === 'all') {
//       let sliced = value
//       let yearly = []

//       for (let i = 0; i < sliced.length; i++) {
//         yearly.push({ t: moment(new Date(sliced[i].t*1000)).format('MMM YYYY'), v: sliced[i].v })
//       }

//       let dates = Array.from(new Set(yearly.map(e => e.t)))

//       let final = dates.map(e => {
//         let temp = yearly.filter(f => f.t === e)
//         return { t: e, v: temp.map(m => m.v).reduce((a, b) => a + b, 0) / temp.length }
//       })

//       result = final
//     }

//     return result.reverse()
//   }

//   useEffect(() => {
//     setFilterData({ price: filterProfitRatio(data.price), supply: filterProfitRatio(data.supply), profitRatio: filterProfitRatio(data.profitRatio), miningDiff: filterProfitRatio(data.miningDiff) })
//   }, [period, data])

//   const seriesPrice = [
//     {
//       name: 'Price(USD)',
//       data: filterData.price.map(e => e.v ? e.v.toFixed(1) : e.v)
//     }
//   ]

//   const optionPrice = {
//     chart: {
//       background: '#222222',
//       toolbar: {
//         tools: {
//           download: true,
//           selection: false,
//           pan: false,
//           zoom: true,
//           zoomin: true,
//           zoomout: true,
//           reset: true
//         }
//       }
//     },
//     colors: ['#00E396'],
//     title: {
//       text: 'Bitcoin: Price History',
//       style: {
//           color: '#FFFFFF',
//           fontSize: '14px',
//           fontWeight: 600,
//       },
//     },
//     stroke: {
//       curve: 'smooth',
//       width: 2,
//     },
//     xaxis: {
//       type: 'category',
//       categories: filterData.price.map(e => e.t),
//       labels: { style: { colors: '#FFFFFF', fontSize: '10px', hideOverlappingLabels: true, trim: true } }
//     },
//     yaxis: {
//       labels: {
//         style: { colors: '#FFFFFF' }
//       },
//     },
//     tooltip: {
//       theme: 'dark',
//     },
//     grid: {
//       show: true,
//       borderColor: 'rgba(255, 255, 255, 0.2)',
//       position: 'back',
//     }
//   }

//   const getSupplyPrice = () => {
//     let supply = filterData.supply.map(e => e.v ? e.v.toFixed(1) : e.v)
//     let price = filterData.price.map(e => e.v ? e.v.toFixed(1) : e.v)
//     let temp = []
    
//     for (let i = 0; i < supply.length; i++) {
//       if (price[i]) {
//         temp.push(price[i]*1)
//       } else {
//         temp.unshift(0)
//       }
//     }

//     return temp
//   }

//   const seriesSupply = [
//     {
//       name: 'Circulating Supply',
//       data: filterData.supply.map(e => e.v ? e.v.toFixed(1) : e.v)
//     },
//     {
//       name: 'Price(USD)',
//       data: getSupplyPrice()
//     }
//   ]

//   const optionSupply = {
//     chart: {
//       background: '#222222',
//       toolbar: {
//         tools: {
//           download: true,
//           selection: false,
//           pan: false,
//           zoom: true,
//           zoomin: true,
//           zoomout: true,
//           reset: true
//         }
//       }
//     },
//     colors: ['#008FFB', '#00E396'],
//     dataLabels: {
//       enabled: false
//     },
//     legend: {
//       labels: {
//         colors: '#FFFFFF'
//       }
//     },
//     title: {
//       text: 'Bitcoin: Circulating Supply [BTC]',
//       style: {
//           color: '#FFFFFF',
//           fontSize: '14px',
//           fontWeight: 600,
//       },
//     },
//     stroke: {
//       curve: 'smooth',
//       width: 2,
//     },
//     xaxis: {
//       type: 'category',
//       categories: filterData.supply.map(e => e.t),
//       labels: { style: { colors: '#FFFFFF', fontSize: '10px', hideOverlappingLabels: true, trim: true } }
//     },
//     yaxis: [
//       {
//         min: filterData.supply.map(e => e.v).sort((a, b) => a - b)[0],
//         labels: {
//           style: {
//             colors: "#FFFFFF"
//           },
//           formatter: (value) => { return value.toFixed(0) }
//         },
//       },
//       {
//         opposite: true,
//         min: getSupplyPrice().sort((a, b) => a - b)[0],
//         labels: {
//           style: {
//             colors: "#FFFFFF"
//           },
//           formatter: (value) => { return value.toFixed(0) }
//         }
//       }
//     ],
//     tooltip: {
//       theme: 'dark',
//     },
//     grid: {
//       show: true,
//       borderColor: 'rgba(255, 255, 255, 0.2)',
//       position: 'back',
//     }
//   }

//   const getMiningDiff = () => {
//     let supply = filterData.miningDiff.map(e => e.v ? e.v.toFixed(1) : e.v)
//     let price = filterData.price.map(e => e.v ? e.v.toFixed(1) : e.v)
//     let temp = []
    
//     for (let i = 0; i < supply.length; i++) {
//       if (price[i]) {
//         temp.push(price[i]*1)
//       } else {
//         temp.unshift(0)
//       }
//     }

//     return temp
//   }

//   const seriesMiningDiff = [
//     {
//       name: 'Difficulty',
//       data: filterData.miningDiff.map(e => e.v ? e.v.toFixed(1) : e.v)
//     },
//     {
//       name: 'Price(USD)',
//       data: getMiningDiff()
//     }
//   ]

//   const optionMiningDiff = {
//     chart: {
//       background: '#222222',
//       toolbar: {
//         tools: {
//           download: true,
//           selection: false,
//           pan: false,
//           zoom: true,
//           zoomin: true,
//           zoomout: true,
//           reset: true
//         }
//       }
//     },
//     colors: ['#008FFB', '#00E396'],
//     title: {
//       text: 'Bitcoin: Mining Difficulty',
//       style: {
//           color: '#FFFFFF',
//           fontSize: '14px',
//           fontWeight: 600,
//       },
//     },
//     dataLabels: {
//       enabled: false
//     },
//     legend: {
//       labels: {
//         colors: '#FFFFFF'
//       }
//     },
//     stroke: {
//       curve: 'smooth',
//       width: 2,
//     },
//     xaxis: {
//       type: 'category',
//       categories: filterData.miningDiff.map(e => e.t),
//       labels: { style: { colors: '#FFFFFF', fontSize: '10px', hideOverlappingLabels: true, trim: true } }
//     },
//     yaxis: [
//       {
//         labels: {
//           style: {
//             colors: "#FFFFFF"
//           }
//         },
//       },
//       {
//         min: getMiningDiff().sort((a, b) => a - b)[0],
//         opposite: true,
//         labels: {
//           style: {
//             colors: "#FFFFFF"
//           },
//           formatter: (value) => { return value.toFixed(0) }
//         }
//       }
//     ],
//     tooltip: {
//       theme: 'dark',
//     },
//     grid: {
//       show: true,
//       borderColor: 'rgba(255, 255, 255, 0.2)',
//       position: 'back',
//     }
//   }

//   const seriesProfitRatio = [
//     {
//       name: 'Ratio',
//       data: filterData.profitRatio.map(e => e.v ? e.v.toFixed(3) : e.v)
//     }
//   ]

//   const optionProfitRatio = {
//     chart: {
//       background: '#222222',
//       toolbar: {
//         tools: {
//           download: true,
//           selection: false,
//           pan: false,
//           zoom: true,
//           zoomin: true,
//           zoomout: true,
//           reset: true
//         }
//       }
//     },
//     colors: ['#FF9800'],
//     title: {
//       text: 'The Spent Output Profit Ratio',
//       style: {
//           color: '#FFFFFF',
//           fontSize: '14px',
//           fontWeight: 600,
//       },
//     },
//     stroke: {
//       curve: 'smooth',
//       width: 2,
//     },
//     xaxis: {
//       type: 'category',
//       categories: filterData.profitRatio.map(e => e.t),
//       labels: { style: { colors: '#FFFFFF', fontSize: '10px', hideOverlappingLabels: true, trim: true } }
//     },
//     yaxis: {
//       labels: {
//         style: { colors: '#FFFFFF' }
//       }
//     },
//     tooltip: {
//       theme: 'dark',
//     },
//     grid: {
//       show: true,
//       borderColor: 'rgba(255, 255, 255, 0.2)',
//       position: 'back',
//     }
//   }

//   return (
//     <div className='fixed top-0 left-[200px] h-full w-[calc(100%-200px)] duration-200 xl:left-0 xl:w-full'>
//       <div className='h-[40px] w-full text-xl text-yellow-400 font-semibold px-6 flex items-center'>Charts</div>
      
//       <div className={`h-[calc(100%-40px)] w-full flex flex-col px-3 pb-2.5 gap-2.5 overflow-y-scroll`}>
//         {(window.localStorage.getItem('subscribe') === 'true') ? <></> : <RegisterReusable/>}

//         {/* selection */}
//         <div className='h-fit w-full sticky top-0 z-50'>
//           <div className='h-fit w-full bg-[#222222] rounded-xl text-sm p-3 gap-5 flex items-center flex-wrap md:gap-2 border border-[#555]'>
//             <p className='text-[#AAAAAA]'>Select Time Period:</p>
//             <button onClick={() => setPeriod('1w')} className={`${(period === '1w') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>1 week</button>
//             <button onClick={() => setPeriod('30d')} className={`${(period === '30d') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>30 days</button>
//             <button onClick={() => setPeriod('3m')} className={`${(period === '3m') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>3 months</button>
//             <button onClick={() => setPeriod('6m')} className={`${(period === '6m') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>6 months</button>
//             <button onClick={() => setPeriod('1y')} className={`${(period === '1y') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>1 year</button>
//             <button onClick={() => setPeriod('5y')} className={`${(period === '5y') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>5 years</button>
//             <button onClick={() => setPeriod('all')} className={`${(period === 'all') ? 'bg-white text-black' : 'bg-transparent text-white'} p-2 md:p-1 rounded-full duration-200 hover:bg-white hover:text-black`}>LifeTime</button>
//           </div>
//         </div>
        
//         {/* charts */}
//         <div className='h-fit w-full grid grid-flow-row gap-2.5'>
//           <div className='h-fit w-full grid grid-cols-2 gap-2.5 xxl:grid-cols-1'>
//             <div className={`h-full w-full bg-[#222222] p-2.5 rounded-xl ${(loading) ? 'animate-pulse' : ''}`}>
//               <Chart type="area" height="400" series={seriesPrice} options={optionPrice}/>
//             </div>
//             <div className={`h-full w-full bg-[#222222] p-2.5 rounded-xl ${(loading) ? 'animate-pulse' : ''}`}>
//               <Chart type="area" height="400" series={seriesSupply} options={optionSupply}/>
//             </div>
//           </div>
//           <div className='h-fit w-full grid grid-cols-2 gap-2.5 xxl:grid-cols-1'>
//             <div className={`h-full w-full bg-[#222222] p-2.5 rounded-xl ${(loading) ? 'animate-pulse' : ''}`}>
//               <Chart type="area" height="400" series={seriesMiningDiff} options={optionMiningDiff}/>
//             </div>
//             <div className={`h-full w-full bg-[#222222] p-2.5 rounded-xl ${(loading) ? 'animate-pulse' : ''}`}>
//               <Chart type="area" height="400" series={seriesProfitRatio} options={optionProfitRatio}/>
//             </div>
//           </div>
//         </div>

//         {/* theory */}
//         <div className='h-fit w-full'>
//           <div className='h-fit w-full bg-[#222222] rounded-xl text-justify p-3 flex flex-col gap-2'>
//             <p className='text-md font-semibold'>What are these charts for?</p>
//             <p className='text-sm text-[#CCCCCC]'>We got you these 4 different charts with different kind of information.</p>
//             <hr className='border-[#555555]'/>
//             <p className='text-md font-semibold'>Bitcoin: Price History</p>
//             <p className='text-sm text-[#CCCCCC]'>The whole history of Bitcoin price.</p>
//             <hr className='border-[#555555]'/>
//             <p className='text-md font-semibold'>Bitcoin: Circulating Supply [BTC]</p>
//             <p className='text-sm text-[#CCCCCC]'>The total amount of all coins ever created/issued, i.e. the circulating supply.</p>
//             <hr className='border-[#555555]'/>
//             <p className='text-md font-semibold'>Bitcoin: Mining Difficulty</p>
//             <p className='text-sm text-[#CCCCCC]'>The current estimated number of hashes required to mine a block. Note: Bitcoin difficulty is often denoted as the relative difficulty with respect to the genesis block, which required approximately 2^32 hashes. For better comparison across blockchains, our values are denoted in raw hashes.</p>
//             <hr className='border-[#555555]'/>
//             <p className='text-md font-semibold'>The Spent Output Profit Ratio</p>
//             <p className='text-sm text-[#CCCCCC]'>The Spent Output Profit Ratio (SOPR) is computed by dividing the realized value (in USD) divided by the value at creation (USD) of a spent output. Or simply: price sold / price paid. This metric was created by Renato Shirakashi. For a detailed commentary see this post.</p>
//             <hr className='border-[#555555]'/>
//           </div>
//         </div>

//       </div>
//     </div>
//   )
// }

// export default Charts