import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa'
import { MdEmail, MdLock } from 'react-icons/md'

import { host } from '../hooks/share'
import deribit from '../assets/deribit.png'
import tradingview from '../assets/tradingview.png'

const Login = ({ setPathname }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [details, setDetails] = useState({ email: '', password: '' })
  const [loading, setLoading] = useState(false)
  const [disableBTN, setDisableBTN] = useState(true)

  useEffect(() => {
    setPathname(location.pathname);
  }, [])

  useEffect(() => {
    if (details.email !== '' && details.password !== '') {
      setDisableBTN(false)
    } else {
      setDisableBTN(true)
    }
  }, [details])

  const loginAPI = () => {
    setLoading(true)
    const url = `http://${host}:5001/get_login`;

    axios.post(url, {
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type, x-access-token",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Content-Type": "application/json",
          "x-access-token": 'N'
      },
      email: details.email, password: details.password
    })
    .then((response) => {
      const obj = response.data

      if (obj.auth === true) {
        window.localStorage.setItem('token', obj.token)
        window.localStorage.setItem('username', obj.username)
        navigate('/charts')
      } else if (obj.message) {
        window.alert(obj.message)
      } else {
        window.alert('Please try again later.')
      }

      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
      setLoading(false)
    })
  }
  
  return (
    <>
      <div className='h-full w-full pt-[80px] text-sm flex flex-col justify-between'>
        {/* login */}
        <div className='z-10 bg-black h-full w-full p-5 flex justify-center sm:p-2.5 login:h-fit'>
          <div className='h-full w-full max-w-[800px] flex items-center justify-center login:h-fit'>
            <div className='h-fit w-full max-w-[400px] bg-transparent rounded-3xl border-0 border-[#222222] shadow-[-10px_10px_50px_-20px_rgba(100,100,100,1),inset_-10px_10px_60px_-20px_rgba(100,100,100,1)] p-10 flex flex-col items-center justify-evenly gap-5 lg:p-5'>
              <div className='w-full'>
                <p className='text-left text-xl text-white font-semibold'>Welcome Back</p>
                <p className='text-left text-[#AAAAAA]'>Let's start exploring more knowledge and strategies.</p>
              </div>
              <div className='w-full flex flex-col'>
                <div className='group h-[50px] w-full flex items-center border-2 border-[#222222] rounded-md px-2.5 gap-2.5 focus-within:border-white'>
                  <div className='h-full w-[30px] flex items-center justify-center'><MdEmail className='h-full w-full fill-[#777777] group-focus-within:fill-white'/></div>
                  <input type="email" placeholder='email' value={details.email} onChange={e => setDetails(prev => {return{...prev, email: e.target.value}})} className='h-full w-full bg-transparent border-none outline-none placeholder-[#777777]' />
                </div>
              </div>
              <div className='w-full flex flex-col'>
                <div className='group h-[50px] w-full flex items-center border-2 border-[#222222] rounded-md px-2.5 gap-2.5 focus-within:border-white'>
                  <div className='h-full w-[30px] flex items-center justify-center'><MdLock className='h-full w-full fill-[#777777] group-focus-within:fill-white'/></div>
                  <input type="password" placeholder='password' value={details.password} onChange={e => setDetails(prev => {return{...prev, password: e.target.value}})} className='h-full w-full bg-transparent border-none outline-none placeholder-[#777777]' />
                </div>
              </div>
              <p onClick={() => navigate('/forgot')} className='w-full text-left text-[#DDDDDD] cursor-pointer duration-200 hover:text-yellow-400 hover:underline hover:underline-offset-4'>Forgot password?</p>
              <button onClick={() => loginAPI()} disabled={disableBTN} className={`h-[50px] w-full text-base text-black font-semibold rounded-md mb-5 flex items-center justify-center ${(disableBTN) ? 'bg-[#AAAAAA]' : 'bg-yellow-400 duration-200 hover:rounded-3xl hover:shadow-[0px_5px_20px_0px_#fde68a] cursor-pointer'}`}>
                {(loading) ? (
                  <div className='relative w-[100px] h-full flex items-center justify-evenly'>
                    <div className='w-1/12 bg-black animate-loading-bars' style={{ animationDelay: '-0.24s' }}/>
                    <div className='w-1/12 bg-black animate-loading-bars' style={{ animationDelay: '-0.12s' }}/>
                    <div className='w-1/12 bg-black animate-loading-bars' style={{ animationDelay: '0' }}/>
                  </div>
                ) : 'LOGIN'}
              </button>
              <p onClick={() => navigate('/register')} className='w-full text-center text-[#DDDDDD] cursor-pointer duration-200 hover:text-yellow-400 hover:underline hover:underline-offset-4'>Not a member? <span className='text-yellow-400'>Register</span></p>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className='z-10 bg-black h-fit w-full px-5 flex justify-center sm:px-2.5'>
          <div className='relative border-t border-[#555555] h-full w-full max-w-[1440px] py-5 text-sm grid grid-rows-[1fr,auto] md:gap-2.5 sm:py-2.5'>
            <div className='h-full w-full grid grid-cols-[1fr,auto] gap-2.5 md:grid-cols-1 md:gap-1'>
              <div>
                <p className='mb-1'>Data Providers:</p>
                <div className='flex flex-wrap gap-2.5 mb-2 md:mb-0'>
                  <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='bg-[#666666] p-2 rounded-md hover:opacity-50'>
                    <img src={deribit} alt="deribit" className='w-20' />
                  </a>
                  <div className='bg-[#666666] p-2 rounded-md'>
                    <img src={tradingview} alt="tradingview" className='w-20' />
                  </div>
                </div>

                <p className='md:hidden'>Options<span className='text-yellow-400'>Crypto</span>Trade - All rights reserved.</p>
              </div>

              <div>
                <div>
                  <div className='my-1 cursor-pointer underline underline-offset-2 duration-200 hover:text-blue-400 hover:underline-offset-4' onClick={() => navigate('/policy')}>Privacy Policy</div>
                  <p>Follow us:</p>
                  <div className='flex flex-wrap gap-5'>
                    <FaFacebookF size={20} className='fill-white cursor-pointer duration-200 hover:fill-blue-400' />
                    <FaInstagram size={20} className='fill-white cursor-pointer duration-200 hover:fill-blue-400' />
                    <FaTwitter size={20} className='fill-white cursor-pointer duration-200 hover:fill-blue-400' />
                  </div>
                </div>
              </div>
            </div>

            <div className='hidden flex-col items-center md:flex'>
              <p className='text-center'>Options<span className='text-yellow-400'>Crypto</span>Trade - All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login