import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { FaFacebookF, FaInstagram, FaTwitter, FaUser } from 'react-icons/fa'
import { MdEmail, MdLock } from 'react-icons/md'
import { BsFillShieldLockFill, BsShieldFillCheck } from 'react-icons/bs'

import { host } from '../hooks/share'
import deribit from '../assets/deribit.png'
import tradingview from '../assets/tradingview.png'

const Forgot = ({ setPathname }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [details, setDetails] = useState({ email: '', username: '', password: '', confirmPassword: '', code: '' })
  const [loading, setLoading] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [disableBTN, setDisableBTN] = useState({ code: true, recover: true })
  const [err, setErr] = useState({
    email: { state: false, msg: '' },
    password: { state: false, msg: '' },
    confirmPassword: { state: false, msg: '' },
    code: { state: false, msg: '' },
  })

  useEffect(() => {
    setPathname(location.pathname);
  }, [])

  useEffect(() => {
    if (details.email !== '' && details.username !== '') {
      setDisableBTN(p => { return { ...p, code: false } })
    } else {
      setDisableBTN(p => { return { ...p, code: true } })
    }

    if (details.code !== '' && details.password !== '' && details.confirmPassword !== '') {
      setDisableBTN(p => { return { ...p, recover: false } })
    } else {
      setDisableBTN(p => { return { ...p, recover: true } })
    }
  }, [details])

  const getCodeAPI = () => {
    const url = `http://${host}:5001/sendSMS`;

    axios.post(url, {
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type, x-access-token",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Content-Type": "application/json",
          "x-access-token": 'N'
      },
      email: details.email, username: details.username
    })
    .catch((error) => {
      window.alert(error);
    })
  }

  const recoverAPI = () => {
    setLoading(true)
    const url = `http://${host}:5001/get_forgetPassword`;

    axios.post(url, {
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type, x-access-token",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          "Content-Type": "application/json",
          "x-access-token": 'N'
      },
      email: details.email, password: details.password, code: details.code
    })
    .then((response) => {
      const obj = response.data

      if (obj.auth === true) {
        window.alert('Password Successfully Changed.')
        navigate('/login')
      } else if (obj.message) {
        window.alert(obj.message)
      } else {
        window.alert('Please try again later.')
      }

      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
      setLoading(false)
    })
  }

  const handleRecovery = () => {
    if (details.password.length > 7 && details.password === details.confirmPassword) {
      recoverAPI()
      return
    }

    if (details.password.length < 8) {
      setErr(p => { return {...p, password: { state: true, msg: "Minimum 8 characters required" }} })
    }

    if (details.confirmPassword !== details.password) {
      setErr(p => { return {...p, confirmPassword: { state: true, msg: "Must match with password" }} })
    }
  }

  const handleSendCode = () => {
    const regex = /(.+)@(.+){2,}\.(.+){2,}/

    if (regex.test(details.email)) {
      getCodeAPI()
      setCodeSent(true)
      return
    }

    if (!regex.test(details.email)) {
      setErr(p => { return {...p, email: { state: true, msg: "Invalid email" }} })
    }
  }
  
  return (
    <>
      <div className='h-full w-full pt-[80px] text-sm flex flex-col justify-between'>
        {/* forgot password */}
        <div className='z-10 bg-black h-full w-full p-5 flex justify-center sm:p-2.5 forgot:h-fit'>
          <div className='h-full w-full max-w-[800px] flex items-center justify-center forgot:h-fit'>
            <div className='h-fit w-full max-w-[400px] bg-transparent rounded-3xl border-0 border-[#222222] shadow-[-10px_10px_50px_-20px_rgba(100,100,100,1),inset_-10px_10px_60px_-20px_rgba(100,100,100,1)] p-10 flex flex-col items-center justify-evenly gap-5 lg:p-5'>
              
              {(codeSent) ? (
                <>
                  <div className='w-full'>
                    <p className='text-left text-xl text-white font-semibold'>Change Password</p>
                    <p className='text-left text-[#AAAAAA]'>We've sent a verification code to your email. If you can not find it, please check your spams and trash as well.</p>
                  </div>
                  <div className='w-full flex flex-col'>
                    {(err.password.state) && (<label htmlFor="email" className='text-red-400 px-2.5'>* {err.password.msg} *</label>)}
                    <div className='group h-[50px] w-full flex items-center border-2 border-[#222222] rounded-md px-2.5 gap-2.5 focus-within:border-white'>
                      <div className='h-full w-[30px] flex items-center justify-center'><MdLock className='h-full w-full fill-[#777777] group-focus-within:fill-white'/></div>
                      <input type="password" placeholder='password' value={details.password} onChange={e => setDetails(prev => {return{...prev, password: e.target.value}})} className='h-full w-full bg-transparent border-none outline-none placeholder-[#777777]' />
                    </div>
                  </div>
                  <div className='w-full flex flex-col'>
                    {(err.confirmPassword.state) && (<label htmlFor="email" className='text-red-400 px-2.5'>* {err.confirmPassword.msg} *</label>)}
                    <div className='group h-[50px] w-full flex items-center border-2 border-[#222222] rounded-md px-2.5 gap-2.5 focus-within:border-white'>
                      <div className='h-full w-[30px] flex items-center justify-center'><BsFillShieldLockFill className='h-full w-full fill-[#777777] group-focus-within:fill-white'/></div>
                      <input type="password" placeholder='confirm password' value={details.confirmPassword} onChange={e => setDetails(prev => {return{...prev, confirmPassword: e.target.value}})} className='h-full w-full bg-transparent border-none outline-none placeholder-[#777777]' />
                    </div>
                  </div>
                  <div className='w-full flex flex-col'>
                    {(err.code.state) && (<label htmlFor="email" className='text-red-400 px-2.5'>* {err.code.msg} *</label>)}
                    <div className='group h-[50px] w-full flex items-center border-2 border-[#222222] rounded-md px-2.5 gap-2.5 focus-within:border-white'>
                      <div className='h-full w-[30px] flex items-center justify-center'><BsShieldFillCheck className='h-full w-full fill-[#777777] group-focus-within:fill-white'/></div>
                      <input type="text" placeholder='verification code' value={details.code} onChange={e => setDetails(prev => {return{...prev, code: e.target.value}})} className='h-full w-full bg-transparent border-none outline-none placeholder-[#777777]' />
                    </div>
                  </div>
                  <button onClick={() => handleRecovery()} disabled={disableBTN.recover} className={`h-[50px] w-full text-base text-black font-semibold rounded-md mb-5 flex items-center justify-center ${(disableBTN.recover) ? 'bg-[#AAAAAA]' : 'bg-yellow-400 duration-200 hover:rounded-3xl hover:shadow-[0px_5px_20px_0px_#fde68a] cursor-pointer'}`}>
                    {(loading) ? (
                      <div className='relative w-[100px] h-full flex items-center justify-evenly'>
                        <div className='w-1/12 bg-black animate-loading-bars' style={{ animationDelay: '-0.24s' }}/>
                        <div className='w-1/12 bg-black animate-loading-bars' style={{ animationDelay: '-0.12s' }}/>
                        <div className='w-1/12 bg-black animate-loading-bars' style={{ animationDelay: '0' }}/>
                      </div>
                    ) : 'CHANGE PASSWORD'}  
                  </button> 
                  <p onClick={() => setCodeSent(false)} className='w-full text-center text-[#DDDDDD] cursor-pointer duration-200 hover:text-yellow-400 hover:underline hover:underline-offset-4'>Details mistaken? <span className='text-yellow-400'>Back</span></p>
                </>
              ) : (
                <>
                  <div className='w-full'>
                    <p className='text-left text-xl text-white font-semibold'>Recover Password</p>
                    <p className='text-left text-[#AAAAAA]'>In a few steps recover your account and start exploring crypto trading strategies again.</p>
                  </div>
                  <div className='w-full flex flex-col'>
                    {(err.email.state) && (<label htmlFor="email" className='text-red-400 px-2.5'>* {err.email.msg} *</label>)}
                    <div className='group h-[50px] w-full flex items-center border-2 border-[#222222] rounded-md px-2.5 gap-2.5 focus-within:border-white'>
                      <div className='h-full w-[30px] flex items-center justify-center'><MdEmail className='h-full w-full fill-[#777777] group-focus-within:fill-white'/></div>
                      <input type="email" placeholder='email' value={details.email} onChange={e => setDetails(prev => {return{...prev, email: e.target.value}})} className='h-full w-full bg-transparent border-none outline-none placeholder-[#777777]' />
                    </div>
                  </div>
                  <div className='w-full flex flex-col'>
                    <div className='group h-[50px] w-full flex items-center border-2 border-[#222222] rounded-md px-2.5 gap-2.5 focus-within:border-white'>
                      <div className='h-full w-[30px] flex items-center justify-center'><FaUser className='h-full w-full fill-[#777777] group-focus-within:fill-white'/></div>
                      <input type="text" placeholder='username' value={details.username} onChange={e => setDetails(prev => {return{...prev, username: e.target.value}})} className='h-full w-full bg-transparent border-none outline-none placeholder-[#777777]' />
                    </div>
                  </div>
                  <button onClick={() => handleSendCode()} disabled={disableBTN.code} className={`h-[50px] w-full text-base text-black font-semibold rounded-md mb-5 ${(disableBTN.code) ? 'bg-[#AAAAAA]' : 'bg-yellow-400 duration-200 hover:rounded-3xl hover:shadow-[0px_5px_20px_0px_#fde68a] cursor-pointer'}`}>SEND CODE</button>
                  <p onClick={() => navigate('/login')} className='w-full text-center text-[#DDDDDD] cursor-pointer duration-200 hover:text-yellow-400 hover:underline hover:underline-offset-4'>Already a member? <span className='text-yellow-400'>Login</span></p>
                </>
              )}
              
            </div>
          </div>
        </div>

        {/* footer */}
        <div className='z-10 bg-black h-fit w-full px-5 flex justify-center sm:px-2.5'>
          <div className='relative border-t border-[#555555] h-full w-full max-w-[1440px] py-5 text-sm grid grid-rows-[1fr,auto] md:gap-2.5 sm:py-2.5'>
            <div className='h-full w-full grid grid-cols-[1fr,auto] gap-2.5 md:grid-cols-1 md:gap-1'>
              <div>
                <p className='mb-1'>Data Providers:</p>
                <div className='flex flex-wrap gap-2.5 mb-2 md:mb-0'>
                  <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='bg-[#666666] p-2 rounded-md hover:opacity-50'>
                    <img src={deribit} alt="deribit" className='w-20' />
                  </a>
                  <div className='bg-[#666666] p-2 rounded-md'>
                    <img src={tradingview} alt="tradingview" className='w-20' />
                  </div>
                </div>

                <p className='md:hidden'>Options<span className='text-yellow-400'>Crypto</span>Trade - All rights reserved.</p>
              </div>

              <div>
                <div>
                  <div className='my-1 cursor-pointer underline underline-offset-2 duration-200 hover:text-blue-400 hover:underline-offset-4' onClick={() => navigate('/policy')}>Privacy Policy</div>
                  <p>Follow us:</p>
                  <div className='flex flex-wrap gap-5'>
                    <FaFacebookF size={20} className='fill-white cursor-pointer duration-200 hover:fill-blue-400' />
                    <FaInstagram size={20} className='fill-white cursor-pointer duration-200 hover:fill-blue-400' />
                    <FaTwitter size={20} className='fill-white cursor-pointer duration-200 hover:fill-blue-400' />
                  </div>
                </div>
              </div>
            </div>

            <div className='hidden flex-col items-center md:flex'>
              <p className='text-center'>Options<span className='text-yellow-400'>Crypto</span>Trade - All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Forgot