import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaUnlockAlt, FaHome } from 'react-icons/fa'
import { RiContactsFill } from 'react-icons/ri'
import { MdPrivacyTip, MdPriceCheck, MdCurrencyExchange } from 'react-icons/md'
import { CgLogOut } from 'react-icons/cg'
import { BiLineChart } from 'react-icons/bi'
import { BsFillFileTextFill } from 'react-icons/bs'

import logo from '../assets/logo.png'

const Sidebar = ({ pathname }) => {
  const navigate = useNavigate()
  const [openSidebar, setOpenSidebar] = useState(false)

  return (
    <div className={`fixed top-0 left-0 bg-[#222222] z-50 h-full w-[200px] duration-200 xl:bg-[#444444] ${(openSidebar) ? 'xl:left-0' : 'xl:left-[-200px]'}`}>
      <div className={`bg-red-500 absolute top-0 left-full w-5 py-2 text-sm font-bold hidden cursor-pointer hover:text-black duration-200 xl:block ${(openSidebar) ? 'xs:left-[calc(100%-20px)]' : ''}`} style={{ writingMode: 'vertical-lr', textOrientation: 'upright' }} onClick={() => setOpenSidebar(prev => !prev)}>MENU</div>

      <div className='h-full w-full overflow-y-scroll'>
        <div className='h-[150px] w-full p-4 mb-5'>
          <img src={logo} alt="logo" className='h-full w-full object-contain brightness-150' />
          <p className='text-3xl text-center text-clip text-transparent bg-clip-text bg-gradient-to-t from-sky-500 to-sky-900 brightness-200' style={{ fontFamily: 'Rubik Distressed, cursive' }}>CROXPOW</p>
        </div>

        <div className='h-[calc(100%-150px)] w-full flex flex-col gap-2'>
          {/* fair price */}
          <div>
            <div className={`h-fit min-h-[40px] w-full flex items-center px-4 py-1 text-sm text-[#AAAAAA]`}>Dashboard</div>
            <div className='h-[50px] w-full flex items-center px-2 py-1'>
              <button onClick={() => {(pathname !== '/charts') && navigate('/charts'); setOpenSidebar(false);} } className={`group h-full w-full flex items-center justify-start px-2 rounded-lg gap-2 cursor-pointer duration-200 hover:text-white hover:bg-[#555555] ${(pathname === '/charts') ? 'bg-[#555555] text-white' : 'text-[#DDDDDD]'}`}><BiLineChart className={`group-hover:fill-white ${(pathname === '/charts') ? 'fill-white' : 'fill-[#DDDDDD]'}`}/>Charts</button>
            </div>
            <div className='h-[50px] w-full flex items-center px-2 py-1'>
              <button onClick={() => {(pathname !== '/fairprice') && navigate('/fairprice'); setOpenSidebar(false);}} className={`group h-full w-full flex items-center justify-start px-2 rounded-lg gap-2 cursor-pointer duration-200 hover:text-white hover:bg-[#555555] ${(pathname === '/fairprice') ? 'bg-[#555555] text-white' : 'text-[#DDDDDD]'}`}><MdPriceCheck className={`group-hover:fill-white ${(pathname === '/fairprice') ? 'fill-white' : 'fill-[#DDDDDD]'}`}/>Options Pricing</button>
            </div>
            <div className='h-[50px] w-full flex items-center px-2 py-1'>
              <button onClick={() => {(pathname !== '/arbitrage') && navigate('/arbitrage'); setOpenSidebar(false);}} className={`group h-full w-full flex items-center justify-start px-2 rounded-lg gap-2 cursor-pointer duration-200 hover:text-white hover:bg-[#555555] ${(pathname === '/arbitrage') ? 'bg-[#555555] text-white' : 'text-[#DDDDDD]'}`}><MdCurrencyExchange className={`group-hover:fill-white ${(pathname === '/arbitrage') ? 'fill-white' : 'fill-[#DDDDDD]'}`}/>Options Arbitrage</button>
            </div>
          </div>

          {/* More */}
          <div>
            <div className={`h-fit min-h-[40px] w-full flex items-center px-4 py-1 text-sm text-[#AAAAAA]`}>More</div>
            <div className='h-[50px] w-full flex items-center px-2 py-1'>
              <button onClick={() => {navigate('/'); setOpenSidebar(false);}}  className={`group h-full w-full flex items-center justify-start px-2 rounded-lg gap-2 cursor-pointer duration-200 hover:text-white hover:bg-[#555555] ${(pathname === '/') ? 'bg-[#555555] text-white' : 'text-[#DDDDDD]'}`}><FaHome className={`group-hover:fill-white ${(pathname === '/') ? 'fill-white' : 'fill-[#DDDDDD]'}`}/>Home</button>
            </div>
            <div className='h-[50px] w-full flex items-center px-2 py-1'>
              <button onClick={() => window.open('https://www.croxroad.co/', '_blank')}  className={`group h-full w-full flex items-center justify-start px-2 rounded-lg gap-2 cursor-pointer text-[#DDDDDD] duration-200 hover:text-white hover:bg-[#555555]`}><BsFillFileTextFill className={`fill-[#DDDDDD] group-hover:fill-white`}/>Blog</button>
            </div>
            <div className='h-[50px] w-full flex items-center px-2 py-1'>
              <button onClick={() => {navigate('/contact'); setOpenSidebar(false);}}  className={`group h-full w-full flex items-center justify-start px-2 rounded-lg gap-2 cursor-pointer duration-200 hover:text-white hover:bg-[#555555] ${(pathname === '/contact') ? 'bg-[#555555] text-white' : 'text-[#DDDDDD]'}`}><RiContactsFill className={`group-hover:fill-white ${(pathname === '/contact') ? 'fill-white' : 'fill-[#DDDDDD]'}`}/>Contact</button>
            </div>
            <div className='h-[50px] w-full flex items-center px-2 py-1'>
              <button onClick={() => {navigate('/policy'); setOpenSidebar(false);}}  className={`group h-full w-full flex items-center justify-start px-2 rounded-lg gap-2 cursor-pointer duration-200 hover:text-white hover:bg-[#555555] ${(pathname === '/policy') ? 'bg-[#555555] text-white' : 'text-[#DDDDDD]'}`}><MdPrivacyTip className={`group-hover:fill-white ${(pathname === '/policy') ? 'fill-white' : 'fill-[#DDDDDD]'}`}/>Policy</button>
            </div>
            {(window.localStorage.getItem('token')) ? (
              <div onClick={() => {window.localStorage.removeItem('token'); window.localStorage.removeItem('username'); window.localStorage.removeItem('subscribe_email'); window.location.reload();}} className='group h-[50px] w-full flex items-center px-2 py-1 cursor-pointer duration-200 hover:bg-red-500'>
                <button className='h-full w-full flex items-center justify-start px-2 bg-red-500 text-white font-semibold rounded-lg gap-2 cursor-pointer'><CgLogOut className='fill-white'/>Logout</button>
              </div>
            ) : (
              <div onClick={() => {navigate('/login'); setOpenSidebar(false);}} className='group h-[50px] w-full flex items-center px-2 py-1 cursor-pointer duration-200 hover:bg-yellow-400'>
                <button className='h-full w-full flex items-center justify-start px-2 bg-yellow-400 text-black font-semibold rounded-lg gap-2 cursor-pointer'><FaUnlockAlt className='fill-black'/>Login</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar