import React, { useState } from 'react'
import axios from 'axios'
import { MdMail } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const RegisterReusable = () => {
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const subscribeAPI = () => {
        setLoading(true)
        axios.post('https://goatrack.io/api/emailoctopus/email', {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                "Content-Type": "application/json",
            },
            data: {
                email_address: email,
                tags: 'croxpowsub'
            }
        })

        .then((response) => {
            console.log(response);
            const data = response.data
            
            if (data?.result?.error) {
                window.alert(data?.result?.error?.message ? data?.result?.error?.message : 'A member already exists with the supplied email address.')
            } else if (data?.result) {
                window.localStorage.setItem('subscribe', 'true')
                window.localStorage.setItem('subscribe_email', email)
                setEmail('')
                navigate(`/thankyou_sub/${email}`)
            } else {
                window.alert("Error!\nPlease try again.")
            }

            setLoading(false)
        })
        
        .catch((err) => {
            console.log(err);
            setLoading(false)
        })
    }

    const handleSubscribe = () => {
        const regex = /(.+)@(.+){2,}\.(.+){2,}/

        if (email === '') {
            window.alert('Please input your email first!')
        } else if (!regex.test(email)) {
            window.alert('Please input a valid email!')
        } else {
            subscribeAPI()
        }
    }

    return (
        <div className='h-fit w-full'>
            <div className='h-fit w-full bg-yellow-400 rounded-xl p-10 flex items-center justify-between gap-5 lg:flex-col lg:justify-center lg:text-center lg:gap-2 sm:px-2.5'>
                <div>
                    <p className='text-4xl text-[#222222] font-semibold sm:text-3xl'>Free Pro Subscription</p>
                    <p className='text-base text-[#222222]'>A simple subscription to change yourself into a PRO Crypto Trader.</p>
                </div>
                <div className='group h-[50px] w-full max-w-[300px] flex items-center border-2 border-[#222222] rounded-full pr-[5px] pl-5 gap-1 sm:pl-2 focus-within:bg-[#222222]'>
                    <div className='h-full min-w-[20px] flex items-center justify-center'><MdMail className='h-full w-full fill-[#222222] group-focus-within:fill-white'/></div>
                    <input type="email" placeholder='email' value={email} onChange={e => setEmail(e.target.value)} className='h-full w-full text-black group-focus-within:text-white bg-transparent border-none outline-none placeholder-[#777777]' />
                    <button onClick={() => handleSubscribe()} className='h-[40px] w-full max-w-[110px] bg-[#222222] px-2.5 font-semibold rounded-full text-white group-focus-within:text-black group-focus-within:bg-white cursor-pointer duration-200 xl:w-36 md:w-full md:max-w-[250px] sm:w-[100px] sm:text-sm'>
                        {(loading) ? (
                        <div className='relative w-full h-full flex items-center justify-evenly'>
                            <div className='w-1/12 bg-black animate-loading-bars' style={{ animationDelay: '-0.24s' }}/>
                            <div className='w-1/12 bg-black animate-loading-bars' style={{ animationDelay: '-0.12s' }}/>
                            <div className='w-1/12 bg-black animate-loading-bars' style={{ animationDelay: '0' }}/>
                        </div>
                        ) : 'SUBSCRIBE'} 
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RegisterReusable