import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa'

import RegisterReusable from './RegisterReusable'

import background from '../assets/background.png'
import cryptoChart from '../assets/cryptoChart.png'
import cryptoChart2 from '../assets/cryptoChart2.png'
import fairprice from '../assets/fairprice.png'
import profitChart from '../assets/profitChart.png'
import refreshData from '../assets/refreshData.png'
import rewards from '../assets/rewards.png'
import quotePic from '../assets/quotePic.png'
import deribit from '../assets/deribit.png'
import tradingview from '../assets/tradingview.png'

const Home = ({ setPathname }) => {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        setPathname(location.pathname);
    }, [])

    return (
        <>
            {/* main home */}
            <div className="relative h-full w-full p-5 flex flex-col items-center justify-center overflow-hidden homepage:h-[500px] md:h-[500px] xs:h-[400px]">
                <img src={background} alt="background" className='absolute h-full w-full object-cover hue-rotate-[260deg] brightness-[70%]' />

                <div className='h-full w-full max-w-[1440px] flex flex-col items-center justify-center'>
                    <p className='z-10 text-8xl text-clip text-transparent bg-clip-text bg-gradient-to-t from-sky-500 to-sky-900 brightness-200 xxl:text-[8vw] lg:text-[50px] xs:text-[40px]' style={{ fontFamily: 'Rubik Distressed, cursive' }}>CROXPOW</p>
                    {/* <p className='z-10 text-8xl text-clip text-transparent bg-clip-text bg-gradient-to-t from-yellow-200 to-orange-600 xxl:text-[8vw] lg:text-[50px] xs:text-[40px]' style={{ fontFamily: 'Rubik Distressed, cursive' }}>CROXPOW</p> */}
                    <p className='z-10 text-8xl leading-normal text-white text-center font-semibold xxl:text-[7.5vw] md:text-[36px] md:leading-snug sm:text-[24px]'>Bitcoin insights at your fingertips.</p>
                    <p className='z-10 text-2xl leading-normal text-white text-center font-semibold xxl:text-[1.5vw] lg:text-[2vw] md:text-[12px] md:leading-snug sm:text-[10px]'>Real-time data, Options pricing and Options arbitrage via Put-Call parity.</p>
                </div>

                {(window.localStorage.getItem('token')) ? (
                    <></>
                ) : (
                    <div className='absolute z-20 bottom-5 w-full max-w-[1440px] flex flex-row gap-2.5 items-center justify-end xxl:px-5 md:flex-col sm:px-2.5 xs:gap-1'>
                        <p className='text-white text-base text-center md:text-sm'>Let's find the perfect strategy for you.</p>
                        <button onClick={() => navigate('/register')} className='text-black text-base font-semibold bg-yellow-400 h-12 w-full max-w-[160px] px-2.5 rounded-lg hover:bg-yellow-100 duration-200 md:max-w-[300px] xs:h-10'>REGISTER</button>
                    </div>
                )}
            </div>

            {/* charts */}
            <div className='z-10 h-fit w-full px-5 py-20 flex justify-center lg:py-10 sm:px-2.5'>
                <div className='h-full w-full max-w-[1440px] bg-[#222222] p-10 rounded-3xl grid grid-cols-[1fr,1fr] items-center gap-5 lg:grid-cols-[1fr] lg:p-5 sm:p-2.5'>
                    <div className='w-full rounded-xl overflow-hidden lg:order-2'>
                        <img src={cryptoChart2} alt="chart" className='h-full w-full object-contain' />
                    </div>
                    <div className='flex flex-col gap-5 lg:items-center lg:order-1'>
                        <p className='text-5xl text-yellow-400 lg:text-center sm:px-2.5 sm:text-3xl'>Charts</p>
                        <p className='text-base lg:text-center sm:text-sm'>We provide easy-to-understand graphical representations to help you make better investment and crypto trading decision.</p>
                        <button onClick={() => navigate('/charts')} className='text-black text-base font-semibold bg-yellow-400 h-12 w-full px-2.5 max-w-[200px] rounded-lg hover:bg-yellow-100 duration-200'>Learn More {'>>'}</button>
                    </div>
                </div>
            </div>

            {/* fair price */}
            <div className='z-10 bg-black h-fit w-full px-5 py-20 flex justify-center lg:py-10 sm:px-2.5'>
                <div className='h-full w-full max-w-[1440px] grid grid-cols-[1fr,1fr] items-center gap-5 lg:grid-cols-[1fr]'>
                    <div className='flex flex-col gap-5 lg:items-center'>
                        <p className='text-5xl lg:text-center sm:px-2.5 sm:text-3xl'>Options <span className='text-yellow-400'>Pricing</span></p>
                        <p className='text-base lg:text-center sm:text-sm'>Use real-time Fair Price to choose which transaction types will most likely make you a profit.</p>
                        <button onClick={() => navigate('/fairprice')} className='text-black text-base font-semibold bg-yellow-400 h-12 w-full px-2.5 max-w-[200px] rounded-lg hover:bg-yellow-100 duration-200'>Learn More {'>>'}</button>
                    </div>
                    <div className='w-full rounded-xl overflow-hidden'>
                        <img src={fairprice} alt="fairprice" className='h-full w-full object-contain' />
                    </div>
                </div>
            </div>

            {/* arbitrage */}
            <div className='z-10 h-fit w-full px-5 flex justify-center sm:px-2.5'>
                <div className='h-full w-full max-w-[1440px] bg-[#222222] py-20 px-5 rounded-3xl flex flex-col items-center gap-5 lg:py-5 sm:p-2.5 '>
                    <p className='text-5xl text-center sm:text-3xl'>Options <span className='text-yellow-400'>Arbitrage</span></p>
                    <p className='text-base text-center sm:text-sm'>Grab your opportunity for high-value Arbitrage by choosing the right options calculated by our Put-Call Parity equation.</p>
                    <button onClick={() => navigate('/arbitrage')} className='text-black text-base font-semibold bg-yellow-400 h-12 w-full px-2.5 max-w-[200px] rounded-lg hover:bg-yellow-100 duration-200'>Learn More {'>>'}</button>
                    
                    <div className='h-fit w-full grid grid-cols-[1fr,1fr,1fr] gap-2.5 lg:grid-cols-[1fr]'>
                        <div className='h-full w-full bg-[#333333] p-3 rounded-xl flex flex-col items-center justify-center gap-3 md:flex-row md:justify-start'>
                            <img src={rewards} alt="rewards" className='h-20 w-20 sm:h-10 sm:w-10' />
                            <div>
                                <p className='text-3xl font-semibold text-center md:text-left md:text-2xl sm:text-lg'>Rewards</p>
                                <p className='text-base text-center md:text-left sm:text-sm'>IF ARB {'>'} 0 ? buy call + sell put + long futures</p>
                            </div>
                        </div>
                        <div className='h-full w-full bg-[#333333] p-3 rounded-xl flex flex-col items-center justify-center gap-3 md:flex-row md:justify-start'>
                            <img src={profitChart} alt="profit-chart" className='h-20 w-20 sm:h-10 sm:w-10' />
                            <div>
                                <p className='text-3xl font-semibold text-center md:text-left md:text-2xl sm:text-lg'>Low-Risk Arbitrage</p>
                                <p className='text-base text-center md:text-left sm:text-sm'>We identify and present suitable low-risk arbitrage opportunities for you for maximum profit.</p>
                            </div>
                        </div>
                        <div className='h-full w-full bg-[#333333] p-3 rounded-xl flex flex-col items-center justify-center gap-3 md:flex-row md:justify-start'>
                            <img src={refreshData} alt="refresh-data" className='h-20 w-20 sm:h-10 sm:w-10' />
                            <div>
                                <p className='text-3xl font-semibold text-center md:text-left md:text-2xl sm:text-lg'>Live Data</p>
                                <p className='text-base text-center md:text-left sm:text-sm'>To guarantee you maximum benefits, we provide you updated results every 5 seconds.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* statement */}
            <div className='z-10 bg-black h-fit min-h-[400px] w-full px-5 flex items-center justify-center md:min-h-[300px] sm:px-2.5 sm:min-h-[240px]'>
                <div className='relative h-full w-full max-w-[1440px] rounded-xl p-2.5 flex flex-col items-center justify-center gap-5'>
                    <img src={quotePic} alt="quote-pic" className='absolute h-[300px] w-[300px] sm:h-[200px] sm:w-[200px]' />
                    <p className='z-10 text-3xl text-center md:text-xl sm:text-base'>"Robust, Reliable and Lightning Fast cryptocurrency derivatives platform to meet the needs for all cryptocurrency traders."</p>
                    <p className='z-10 text-base text-center md:text-sm sm:text-xs'>Options<span className='text-yellow-400'>Crypto</span>Trade</p>
                </div>
            </div>

            {/* statement */}
            <div className='z-10 h-fit w-full px-5 pb-5 flex justify-center sm:px-2.5'>
                <div className='h-fit w-full max-w-[1440px]'>
                    {(window.localStorage.getItem('subscribe') === 'true') ? <></> : <RegisterReusable/>}
                </div>
            </div>

            {/* footer */}
            <div className='z-10 bg-black h-fit w-full px-5 flex justify-center sm:px-2.5'>
                <div className='relative border-t border-[#555555] h-full w-full max-w-[1440px] py-5 text-sm grid grid-rows-[1fr,auto] md:gap-2.5 sm:py-2.5'>
                    <div className='h-full w-full grid grid-cols-[1fr,auto] gap-2.5 md:grid-cols-1 md:gap-1'>
                        <div>
                            <p className='mb-1'>Data Providers:</p>
                            <div className='flex flex-wrap gap-2.5 mb-2 md:mb-0'>
                                <a href='https://www.deribit.com/?reg=16098.1739' target='_blank' className='bg-[#666666] p-2 rounded-md hover:opacity-50'>
                                    <img src={deribit} alt="deribit" className='w-20' />
                                </a>
                                <div className='bg-[#666666] p-2 rounded-md'>
                                    <img src={tradingview} alt="tradingview" className='w-20' />
                                </div>
                            </div>

                            <p className='md:hidden'>Options<span className='text-yellow-400'>Crypto</span>Trade - All rights reserved.</p>
                        </div>

                        <div>
                            <div>
                                <div className='my-1 cursor-pointer underline underline-offset-2 duration-200 hover:text-blue-400 hover:underline-offset-4' onClick={() => navigate('/policy')}>Privacy Policy</div>
                                <p>Follow us:</p>
                                <div className='flex flex-wrap gap-5'>
                                    <FaFacebookF size={20} className='fill-white cursor-pointer duration-200 hover:fill-blue-400' />
                                    <FaInstagram size={20} className='fill-white cursor-pointer duration-200 hover:fill-blue-400' />
                                    <FaTwitter size={20} className='fill-white cursor-pointer duration-200 hover:fill-blue-400' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='hidden flex-col items-center md:flex'>
                        <p className='text-center'>Options<span className='text-yellow-400'>Crypto</span>Trade - All rights reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home